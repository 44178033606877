import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Components/Button';
import { SEE_USER, EDIT_USER } from '../queries';
import PollinLogo from '../Components/PollinLogo';
import Form from '../Components/Form';
import Wrapper from '../Components/Wrapper';
import Input from '../Components/Input';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import NavButton from '../Components/NavButton';

const Profile = ({
  history,
  match: {
    params: { username }
  },
  user
}) => {
  const { data, loading } = useQuery(SEE_USER, {
    variables: { username }
  });
  const [editUserMutation] = useMutation(EDIT_USER);
  const [onEdit, setOnEdit] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (data && data.seeUser) {
      user.build(data.seeUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const editPermission = () => {
    return user.isSelf || user.role === 'ADMIN';
  };

  const handleEdit = () => {
    if (onEdit) {
      setOnEdit(false);
    } else {
      setOnEdit(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setButtonLoading(true);

    const editData = {
      id: data.seeUser.id,
      username: data.seeUser.username !== user.username ? user.username : null,
      email: data.seeUser.email !== user.email ? user.email : null,
      address: data.seeUser.address !== user.address ? user.address : null,
      name: data.seeUser.name !== user.name ? user.name : null,
      tel: data.seeUser.tel !== user.tel ? user.tel : null,
      organ: data.seeUser.organ !== user.organ ? user.organ : null,
      organTel: data.seeUser.organTel !== user.organTel ? user.organTel : null
    };

    try {
      const {
        data: { editUser }
      } = await editUserMutation({
        variables: { input: editData }
      });
      user.build(editUser);
      toast.success('수정 완료');
    } catch (err) {
      if (err.graphQLErrors[0]) {
        const errorMessage = err.graphQLErrors[0].message;
        if (errorMessage === '이미 사용중인 유저 닉네임입니다.') {
          toast.error(errorMessage);
          return;
        } else if (errorMessage === '이미 사용중인 이메일 주소입니다.') {
          toast.error(errorMessage);
          return;
        }
      }
      toast.error('잠시 후 다시 시도해 주세요.');
    } finally {
      setButtonLoading(false);
      setOnEdit(false);
    }
  };

  return (
    <Wrapper>
      <PollinLogo color='grey' />
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: -20,
            padding: 20
          }}
        >
          {data && data.seeUser && !onEdit && (
            <>
              <NavBox>
                {user.role !== 'ADMIN' && (
                  <NavButton
                    text='홈'
                    arrow='left'
                    onClick={() => {
                      history.push(`/`);
                      window.fbq('track', 'Search');
                    }}
                  />
                )}
              </NavBox>
              <h1>{user.isSelf ? '내 정보' : user.username}</h1>
            </>
          )}
          {data && data.seeUser && (
            <ProfileForm onSubmit={handleSubmit}>
              <div>이메일</div>
              <Input
                name='email'
                value={user.email}
                onChange={user.onChange}
                disabled={true}
              />
              <div>표시되는 이름</div>
              <Input
                name='username'
                value={user.username}
                onChange={user.onChange}
                disabled={!onEdit}
                required={true}
              />
              {editPermission() && (
                <>
                  {!onEdit && (
                    <>
                      <div>보유 크레딧</div>
                      <CreditWrapper>
                        <Input value={user.credit} disabled={true} />
                        <Button
                          type='button'
                          text='충전'
                          onClick={() => {
                            history.push(`/charging/${username}`);
                            window.fbq('track', 'InitiateCheckout');
                          }}
                        />
                      </CreditWrapper>
                    </>
                  )}
                  <div>소속 기관명</div>
                  <Input
                    name='organ'
                    value={user.organ || ''}
                    onChange={user.onChange}
                    disabled={!onEdit}
                    required={false}
                  />
                  <div>담당자 이름</div>
                  <Input
                    name='name'
                    value={user.name || ''}
                    onChange={user.onChange}
                    disabled={!onEdit}
                    required={false}
                  />
                  <div>담당자 연락처</div>
                  <Input
                    name='tel'
                    type='tel'
                    placeholder={onEdit ? '010-1234-5678' : ''}
                    value={user.tel || ''}
                    onChange={user.onChange}
                    disabled={!onEdit}
                    required={false}
                  />
                  {!onEdit && (
                    <>
                      <Button
                        type='button'
                        text='내 정보 수정'
                        theme='white'
                        onClick={handleEdit}
                      />
                    </>
                  )}
                  {onEdit && (
                    <>
                      <ButtonBox>
                        <Button
                          className='Cancel-Button'
                          type='button'
                          text='취소'
                          onClick={handleEdit}
                          theme='white'
                        />
                        <Button
                          type='submit'
                          text='수정 완료'
                          loading={buttonLoading}
                        />
                      </ButtonBox>
                    </>
                  )}
                </>
              )}
            </ProfileForm>
          )}

          {loading && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 150
              }}
            >
              <CircularProgress color='inherit' size='24px' />
            </div>
          )}
        </div>
      </Form>
    </Wrapper>
  );
};
export default inject('user')(withRouter(observer(Profile)));

const NavBox = styled.div`
  display: flex;
  margin: 0px -15px;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 5px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 10px;
  .Cancel-Button {
    width: 80px;
    margin-right: 7px;
  }
`;

const CreditWrapper = styled.div`
  display: flex;
  button {
    width: 80px;
    height: 40px;
    margin-left: 8px;
    margin-top: 0px !important;
    margin-bottom: 15px;
  }
`;
