import React from 'react';
import styled from 'styled-components';

export default ({ children, selected, onClick }) => (
  <Container onClick={onClick} selected={selected}>
    {children}
  </Container>
);
const Container = styled.div`
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #999;
  border-radius: 4px;
  cursor: pointer;
  background: ${props => (props.selected ? '#e6e6e6' : 'white')}
  :hover {
    background: #e6e6e6;
  }
  p:first-child {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;
