import { observable, action } from 'mobx';

export default class QuestionStore {
  @observable index;
  @observable title;
  @observable description;
  @observable type;
  @observable usingLocation;
  @observable items;

  itemIndex;

  constructor(root, index) {
    this.root = root;
    this.index = index;
    this.title = '';
    this.description = '';
    this.type = 'ONLY';
    this.items = [
      { index: 0, title: '' },
      { index: 1, title: '' }
    ];
    this.itemIndex = 2;
  }

  @action
  build = ({ title, description, type, items }, index) => {
    this.index = index;
    this.title = title;
    this.description = description;
    this.type = type;
    this.items = items.map((item, index) => ({
      index,
      title: item.title
    }));
    this.itemIndex = this.items.length;
  };

  @action
  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'description' && value.length > 250) return;
    if (name === 'title' && value.length > 150) return;
    this[name] = value;
  };

  /**
   * Item
   */
  @action
  addItem = () => {
    if (this.items.length < 35) {
      this.items.push({ id: '_', index: this.itemIndex++, title: '' });
    }
  };

  @action
  removeItem = (e, phase) => {
    if (
      phase !== 'edit' ||
      window.confirm(
        '항목을 삭제하면 해당하는 득표 내역이 삭제됩니다.\n그래도 삭제 하시나요?'
      )
    ) {
      const { value: index } = e.target;
      this.items = this.items.filter(item => item.index !== parseInt(index));
    }
  };

  @action
  handleItemChange = e => {
    const { name: index, value } = e.target;
    if (value.length > 150) return;
    this.items[index].title = value;
  };
}
