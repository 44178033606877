import React from 'react';
import styled from 'styled-components';

const Wrapper = ({ children, className }) => (
  <Container className={className}>
    {process.env.REACT_APP_STAGE !== 'prod' && (
      <div style={{ color: 'red', fontSize: 22 }}>개발용</div>
    )}
    {children}
  </Container>
);
export default Wrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  justify-content: center;
  padding-bottom: 50px;
`;
