import { useState } from 'react';

const useInputLimit = (defaultValue, limit = 150) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = e => {
    const {
      target: { value }
    } = e;
    if (value.length <= limit) setValue(value);
  };
  return { value, onChange, setValue };
};

export default useInputLimit;
