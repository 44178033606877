import React from 'react';
import styled from 'styled-components';

const Input = ({
  className,
  placeholder,
  name,
  value,
  type = 'text',
  required = true,
  disabled = false,
  onChange,
  onKeyPress,
  size = '16px'
}) => (
  <Container
    className={className}
    name={name}
    placeholder={placeholder}
    value={value}
    type={type}
    required={required}
    disabled={disabled}
    onChange={onChange}
    onKeyPress={onKeyPress}
    size={size}
  />
);
export default Input;

const Container = styled.input`
  border: 0;
  border: 1px solid #999;
  border-radius: 4px;
  background-color: ${props => (props.disabled ? '#F5F5F5' : 'white')};
  height: 40px;
  font-size: ${props => props.size}
  padding: 0px 15px;
  width: 100%;
  :: placeholder {
    color: #999;
    font-size: 14px;
  }
  input[type='text'],input[type='number'],select: focus;
`;
