import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useSubscription } from 'react-apollo-hooks';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { VOTE_SUBSCRIPTION, SEE_POLL } from '../queries';
import useDemensions from '../Hooks/useDimensions';

import Loader from '../Components/Loader';
import Wrapper from '../Components/Wrapper';
import LargeText from '../Components/LargeText';
import PollinLogo from '../Components/PollinLogo';
import { Helmet } from 'react-helmet-async';
import InfoText from '../Components/InfoText';
import Emoji from '../Components/Emoji';
import Question from '../Components/Question';
import Form from '../Components/Form';
import NavButton from '../Components/NavButton';

const Result = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const [dimRef, { width }] = useDemensions({ scrollEvent: false });
  const { data, loading, error } = useQuery(SEE_POLL, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  /**
   * 자동으로 data.seePoll 에 반영됨
   */
  useSubscription(VOTE_SUBSCRIPTION, {
    variables: { id }
  });

  useEffect(() => {
    if (error) {
      toast.error('존재하지 않는 투표입니다.');
      history.push('/');
    }
    window.scrollTo(0, 0);
  });

  if (loading || error) return <Loader />;
  return (
    data.seePoll && (
      <ResultWrapper>
        <Helmet title='투표 결과 | Pollin' />
        <div ref={dimRef}>
          <PollinLogo color='grey' width='w' />
          <Form width='w'>
            <NavBox>
              <NavButton
                text='투표 관리'
                arrow='left'
                onClick={() => history.push(`/manage/${id}`)}
              />
            </NavBox>
            <LargeText text={data.seePoll.title} />
            <DescriptionText>{data.seePoll.description} </DescriptionText>
            {data.seePoll.titleImage && (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={data.seePoll.titleImage}
                  alt='타이틀 이미지'
                  width={`${width - 60}px`}
                />
              </div>
            )}
            <TurnCount>
              <InfoText text={`${data.seePoll.turnCount} 명 참여`} />
              <Emoji className='eyes' symbol='👀' />
            </TurnCount>
          </Form>

          {data.seePoll.questions.map((question, index) => (
            <Question
              key={index}
              index={index}
              question={question}
              mode='print'
              disabled={true}
            />
          ))}
        </div>
      </ResultWrapper>
    )
  );
};
export default withRouter(Result);

const ResultWrapper = styled(Wrapper)`
  span,
  div {
    word-wrap: break-word;
  }
  .control-wrap {
    border-bottom: 1px solid #999;
    > p {
      font-wight: 500;
      color: #999;
    }
  }
  .control-switch {
    display: flex;
    align-items: center;
    padding: 15px 0px;
  }
  .Qna-wrap {
    padding-bottom: 20px;
  }
`;

const TurnCount = styled.div`
  color: #c7c7c7;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
  span {
    color: #999;
    font-weight: 600px;
    font-size: 16px;
  }
  .eyes {
    margin-left: 10px;
  }
`;

const DescriptionText = styled.div`
  fontweight: 500;
  color: #999;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const NavBox = styled.div`
  display: flex;
  margin-top: -15px;
  margin-bottom: 20px;
  button {
    :first-child {
      margin-right: 4px;
    }
    :last-child {
      margin-left: 4px;
    }
  }
`;
