import React from 'react';
import styled from 'styled-components';

const InfoText = ({ className, text, size = '14px' }) => (
  <Container className={className} size={size}>
    {text}
  </Container>
);
export default InfoText;

const Container = styled.span`
  color: #999;
  font-size: ${props => props.size}
  font-weight: 600;
  word-break: keep-all;
`;
