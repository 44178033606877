import gql from 'graphql-tag';

// *** Local
export const LOCAL_LOG_IN = gql`
  mutation logUserIn($token: String!) {
    logUserIn(token: $token) @client
  }
`;

export const LOCAL_LOG_OUT = gql`
  mutation logUserOut {
    logUserOut @client
  }
`;

// *** Auth
export const LOG_IN = gql`
  mutation requestSecret($email: String!) {
    requestSecret(email: $email)
  }
`;

export const CREATE_USER = gql`
  mutation createUser($username: String!, $email: String!) {
    createUser(username: $username, email: $email)
  }
`;

export const CONFIRM_SECRET = gql`
  mutation confirmSecret($secret: String!, $email: String!) {
    confirmSecret(secret: $secret, email: $email)
  }
`;

export const ADMIN_LOGIN = gql`
  mutation adminLogin($email: String!, $adminSecret: String!) {
    adminLogin(email: $email, adminSecret: $adminSecret)
  }
`;

export const SEE_ALL = gql`
  {
    seeAll {
      id
      title
      isOpen
      usingLocation
      host {
        username
      }
      createdAt
    }
  }
`;

export const SEARCH_BY_HOST = gql`
  mutation searchByHost($username: String!) {
    searchByHost(username: $username) {
      id
      title
      isOpen
      usingLocation
      host {
        username
      }
      createdAt
    }
  }
`;

export const SEARCH_BY_TITLE = gql`
  mutation searchByTitle($title: String!) {
    searchByTitle(title: $title) {
      id
      title
      isOpen
      isTest
      usingLocation
      host {
        username
      }
      createdAt
    }
  }
`;

export const SEE_ALL_CHARGE_REQUEST = gql`
  {
    seeAllChargeRequest {
      id
      isRefund
      status
      adminNote
      amount
      depositName
      depositNumber
      depositTel
      processedBy {
        username
      }
      user {
        username
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CURRENT_IP = gql`
  {
    getCurrentIp
  }
`;

export const REQUEST_KSNET_PAYMENT = gql`
  mutation requestKsnetPayment($merchantUid: String!) {
    requestKsnetPayment(merchantUid: $merchantUid)
  }
`;

export const QUERY_PAY_RESULT = gql`
  mutation queryPayResult($paymentId: ID) {
    queryPayResult(paymentId: $paymentId)
  }
`;

export const COMFIRM_PAYMENT = gql`
  mutation confirmPayment($merchantUid: String!, $amount: Int!) {
    confirmPayment(merchantUid: $merchantUid, amount: $amount)
  }
`;

export const SEE_USER = gql`
  query seeUser($username: String) {
    seeUser(username: $username) {
      id
      email
      username
      address
      name
      tel
      organ
      organTel
      ips {
        id
        address
      }
      credit
      creditHistories {
        id
        type
        amount
        cause
        createdAt
        updatedAt
      }
      refundableCredit
      role
      isSelf
      createdAt
      updatedAt
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      id
      email
      username
      address
      name
      tel
      organ
      organTel
      ips {
        id
        address
      }
      credit
      role
      isSelf
      createdAt
      updatedAt
    }
  }
`;

export const SEE_HOSTING = gql`
  {
    seeHosting {
      host {
        username
      }
      polls {
        id
        title
        description
        isOpen
        createdAt
      }
    }
  }
`;

export const CREATE_POLL = gql`
  mutation createPoll($input: PollCreateInput!) {
    createPoll(input: $input)
  }
`;

export const EDIT_POLL = gql`
  mutation editPoll($input: PollEditInput!) {
    editPoll(input: $input)
  }
`;

export const SEE_LIMITED_POLL = gql`
  query seeLimitedPoll($id: ID!) {
    seeLimitedPoll(id: $id) {
      id
      title
      description
      titleImage
      wifi {
        ssid
        password
      }
      isOpen
      isTest
      usingLocation
      usingMessage
      usingLot
      checkIp
      host {
        username
      }
      questions {
        id
        title
        description
        type
        items {
          id
          title
        }
      }
    }
    getCurrentIp
  }
`;

export const SEE_POLL = gql`
  query seePoll($id: ID!) {
    seePoll(id: $id) {
      title
      description
      titleImage
      isOpen
      isTest
      usingLocation
      usingMessage
      usingLot
      creditModel
      questions {
        title
        description
        type
        items {
          id
          title
          voteCount
        }
      }
      wifi {
        id
        ssid
        password
      }
      ips {
        id
        address
      }
      turnCount
    }
    getCurrentIp
  }
`;

export const SEE_MESSAGES = gql`
  query seeMessages($pollId: ID!, $skip: Int) {
    seeMessages(pollId: $pollId, skip: $skip) {
      id
      name
      message
      createdAt
    }
    messageLength(pollId: $pollId)
  }
`;

export const SEE_LOT_INFO = gql`
  query seeLotInfo($pollId: ID!) {
    seeLotInfo(pollId: $pollId) {
      lotEntryCount
      lotWinHistory
    }
  }
`;

export const FINISH_TEST = gql`
  mutation finishTest($pollId: ID!) {
    finishTest(pollId: $pollId)
  }
`;

export const ADD_IP = gql`
  mutation addIp($pollId: ID!) {
    addIp(pollId: $pollId)
  }
`;

export const ENTER_LOT = gql`
  mutation enterLot($pollId: ID!) {
    enterLot(pollId: $pollId)
  }
`;

export const CAST_LOT = gql`
  mutation castLot($pollId: ID!) {
    castLot(pollId: $pollId)
  }
`;

export const LOT_SUBSCRIPTION = gql`
  subscription lotSubscription($pollId: ID!) {
    lotSubscription(pollId: $pollId)
  }
`;

export const VOTE = gql`
  mutation vote($id: ID!, $itemIds: [String!]!, $captchaToken: String!) {
    vote(id: $id, itemIds: $itemIds, captchaToken: $captchaToken)
  }
`;

export const VOTE_SUBSCRIPTION = gql`
  subscription voteSubscription($id: ID!) {
    voteSubscription(id: $id) {
      id
      title
      description
      titleImage
      isOpen
      usingLocation
      questions {
        title
        description
        type
        items {
          id
          title
          voteCount
        }
      }
      ips {
        id
        address
      }
      turnCount
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($pollId: ID!, $name: String, $message: String!) {
    sendMessage(pollId: $pollId, name: $name, message: $message)
  }
`;

export const TOGGLE_OPEN = gql`
  mutation toggleOpen($id: ID!, $isOpen: Boolean!) {
    toggleOpen(id: $id, isOpen: $isOpen)
  }
`;

export const TOGGLE_USING_LOCATION = gql`
  mutation toggleUsingLocation($id: ID!, $usingLocation: Boolean!) {
    toggleUsingLocation(id: $id, usingLocation: $usingLocation)
  }
`;

export const REQUEST_CHARGE = gql`
  mutation requestCharge($input: ChargeRequestCreateInput!) {
    requestCharge(input: $input) {
      id
      status
    }
  }
`;

export const CONFIRM_CHARGE_REQUEST = gql`
  mutation confirmChargeRequest($requestId: String!) {
    confirmChargeRequest(requestId: $requestId)
  }
`;

export const CANCEL_CHARGE_REQUEST = gql`
  mutation cancelChargeRequest($requestId: String!) {
    cancelChargeRequest(requestId: $requestId)
  }
`;

export const SEE_MY_CHARGE_REQUEST = gql`
  {
    seeMyChargeRequest {
      id
      isRefund
      status
      adminNote
      amount
      depositName
      depositNumber
      depositTel
      processedBy {
        username
      }
      user {
        username
      }
      createdAt
      updatedAt
    }
  }
`;
