import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Components/Button';
import { Dot, ArrowRight } from '../Components/Icons';
import { SEE_HOSTING, LOCAL_LOG_OUT } from '../queries';
import PollinLogo from '../Components/PollinLogo';
import Form from '../Components/Form';
import Wrapper from '../Components/Wrapper';
import NavButton from '../Components/NavButton';

const Home = ({ history }) => {
  const { data, loading } = useQuery(SEE_HOSTING, {
    fetchPolicy: 'network-only'
  });
  const [logOutMutation] = useMutation(LOCAL_LOG_OUT);

  const displayDate = tz => {
    const dt = new Date(tz);
    return dt.getFullYear() + '/' + (dt.getMonth() + 1) + '/' + dt.getDate();
  };

  return (
    <Wrapper>
      <PollinLogo />
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: -20,
            padding: 20,
            marginBottom: 20,
            borderBottom: '1px solid #e6e6e6'
          }}
        >
          {!loading && (
            <NavBox>
              <NavButton text='로그아웃' onClick={() => logOutMutation()} />
              <NavButton
                text='내 정보'
                arrow='right'
                onClick={() =>
                  history.push(`/profile/${data.seeHosting.host.username}`)
                }
              />
            </NavBox>
          )}
          <FormTitle>
            {data && data.seeHosting && (
              <>
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: 22,
                      fontWeight: 800,
                      color: '#262626'
                    }}
                  >
                    {data.seeHosting.host.username}
                  </span>
                  <span
                    style={{ fontWeight: 400, marginLeft: 4, color: '#999' }}
                  >
                    님의 투표
                  </span>
                </div>
              </>
            )}
          </FormTitle>
          {data &&
            data.seeHosting &&
            data.seeHosting.polls.map(poll => (
              <ItemRows
                key={poll.id}
                onClick={() => history.push(`/manage/${poll.id}`)}
              >
                <Dot fill={poll.isOpen ? '#0FBE00' : '#CD4141'} />
                <span
                  style={{
                    flex: 1,
                    textAlign: 'left',
                    fontSize: 20,
                    fontWeight: 600
                  }}
                >
                  {poll.title}
                </span>
                <span style={{ flexShrink: 0 }}>
                  {displayDate(poll.createdAt)}
                </span>
                <ArrowRight />
              </ItemRows>
            ))}
          {data && data.seeHosting && data.seeHosting.polls.length === 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: 150
              }}
            >
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: '#999',
                  textAlign: 'center'
                }}
              >
                아직 개설한 투표가 없습니다
                <br />
                지금 만들어 보세요
              </span>
            </div>
          )}
          {loading && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 150
              }}
            >
              <CircularProgress color='inherit' size='24px' />
            </div>
          )}
        </div>
        <Button text={'새 투표 생성'} onClick={() => history.push('/create')} />
      </Form>
    </Wrapper>
  );
};
export default withRouter(Home);

const ItemRows = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px -15px;
  border: 1px solid #999;
  border-radius: 6px;
  svg {
    margin: 5px 10px 5px 5px;
    :last-child {
      margin-right: -5px;
    }
  }
  :hover {
    cursor: pointer;
    background-color: #e6e6e6;
  }
`;

const FormTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
`;

const NavBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0px -15px;
  margin-top: -15px;
  margin-bottom: 20px;
  button {
    :first-child {
      margin-right: 4px;
    }
    :last-child {
      margin-left: 4px;
    }
  }
`;
