import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = ({
  text,
  onClick,
  className,
  loading = false,
  disabled = false,
  type = 'submit',
  theme = 'black'
}) => {
  return (
    <Container
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
      theme={theme}
    >
      {loading ? <CircularProgress color='inherit' size='16px' /> : text}
    </Container>
  );
};
export default Button;

const Container = styled.button`
  width: 100%;
  border: ${props => (props.theme === 'black' ? '0' : '1px solid #262626')};
  outline: 0;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => (props.theme === 'black' ? 'white' : 'black')};
  background-color: ${props =>
    props.theme === 'black' ? (props.disabled ? '#999' : '#262626') : 'white'};
  text-align: center;
  padding: 7px 0px;
  font-size: 14px;
  cursor: pointer;
  height: 37px;
`;
