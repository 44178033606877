import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import CreatePresenter from './CreatePresenter';
import useDemensions from '../../Hooks/useDimensions';
import { CREATE_POLL, SEE_USER } from '../../queries';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

const CreateContainer = ({ history, poll, user }) => {
  const { data: userData } = useQuery(SEE_USER);
  const [createPollMutation] = useMutation(CREATE_POLL);
  const [dimRef, { width }] = useDemensions({ scrollEvent: false });

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      user.build(userData.seeUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const isValidIp = ip => {
    if (ip.split('.').length !== 4) {
      throw Error();
    }
    ip.split('.').forEach(block => {
      if (block < 0 || block > 255) throw Error();
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    window.fbq('track', 'AddToCart');

    if (poll.usingLocation & (user.credit - poll.creditModel * 30000 < 0)) {
      return toast.error('보유 크레딧이 부족합니다');
    }
    if (poll.questions.length < 1) {
      return toast.error('한 개 이상의 질문을 작성해 주세요.');
    }
    if (poll.usingLocation & !poll.creditModel) {
      return toast.error('예상 참여 인원을 선택해 주세요.');
    }

    const {
      title,
      description,
      usingLocation,
      usingMessage,
      usingLot,
      titleImage,
      creditModel
    } = poll;
    const data = {
      title,
      description,
      usingLocation,
      usingMessage,
      usingLot,
      creditModel: usingLocation ? creditModel : null,
      questions: poll.questions.map(({ title, description, type, items }) => ({
        title,
        description,
        type,
        items: items.map(({ title }) => ({
          title
        }))
      }))
    };

    if (usingLocation) {
      if (poll.ips.length <= 0) {
        return toast.error(
          '위치 제한 기능을 사용하려면 하나 이상의 네트워크 주소를 설정해 주세요.'
        );
      }
      try {
        // *** ips
        poll.ips.forEach(({ address }) => !isValidIp(address));
        data.ips = poll.ips.map(({ address }) => address);
        // *** wifi
        data.wifi = poll.wifi;
      } catch {
        return toast.error(
          '네트워크 주소가 유효하지 않습니다. 다시한번 확인해 주세요.'
        );
      }
    }

    try {
      setButtonLoading(true);
      // titleImage
      if (titleImage) {
        const axiosEndpoint =
          process.env.REACT_APP_HTTP_ENDPOINT + '/api/upload';
        const formData = new FormData();
        formData.append('file', titleImage);
        const {
          data: { location }
        } = await axios.post(axiosEndpoint, formData);
        data.titleImage = location;
      }

      const {
        data: { createPoll: id }
      } = await createPollMutation({
        variables: { input: data }
      });
      poll.id = id;

      toast.success('투표 생성이 완료됐습니다.');
      window.scrollTo(0, document.body.scrollHeight);
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        const errorMessage = err.graphQLErrors[0].message;
        if (errorMessage === 'Too many networks') {
          toast.error('등록하려는 네트워크가 너무 많습니다.');
        } else if (errorMessage === 'Too many questions') {
          toast.error('작성된 질문이 너무 많습니다.');
        } else if (
          errorMessage === 'You need to log in to perform this action'
        ) {
          localStorage.removeItem('token');
          toast.error('페이지 새로 고침 후 다시 시도하세요.');
        }
      } else {
        toast.error('잠시 후 다시 시도해 주세요.');
      }
      setButtonLoading(false);
    }
  };

  return (
    <CreatePresenter
      ref={dimRef}
      width={width}
      history={history}
      buttonLoading={buttonLoading}
      onSubmit={onSubmit}
      poll={poll}
      user={user}
      mode='create'
    />
  );
};

export default inject(
  'poll',
  'user'
)(compose(withRouter, observer)(CreateContainer));
