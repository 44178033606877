import React from 'react';
import styled from 'styled-components';

const IconButton = ({
  className,
  children,
  style,
  onClick,
  value,
  color,
  disabled = false,
  type = 'button'
}) => (
  <Container
    className={className}
    style={style}
    onClick={onClick}
    type={type}
    value={value}
    color={color}
    disabled={disabled}
  >
    {children}
  </Container>
);
export default IconButton;

const Container = styled.button`
  display: flex;
  align-content: center;
  align-items: center;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  z-index: 0;
  padding: 0;
  svg {
    z-index: -1;
    margin: 10px;
    fill: ${props => props.color};
  }
  outline: 0;
`;
