import React from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from './Button';
import Question from './Question';
import { Info, Wifi, Key } from './Icons';
import Form from './Form';
import Emoji from './Emoji';
import InfoText from './InfoText';

const VotePresenter = ({
  recaptchaRef,
  selectedValue,
  buttonLoading,
  data,
  onSubmit,
  handleSelect,
  captchaToken,
  handleCaptcha,
  currentIp,
  isValidIp,
  isTest,
  handleIpAdd
}) => {
  if (!isValidIp) {
    return (
      <Form width='w'>
        <Warnning>
          <Info />
          <div style={{ marginLeft: 15, width: '100%' }}>
            {data.wifi ? (
              <>
                <div>
                  아래 Wi-Fi에 연결해 주세요
                  <Emoji symbol='😮' />
                </div>
                <br />
                <WifiInfo>
                  <ul>
                    <li>
                      <div>
                        <Wifi />
                        네트워크 이름
                      </div>
                      <strong>{data.wifi.ssid}</strong>
                    </li>
                    <li>
                      <div>
                        <Key />
                        비밀번호
                      </div>
                      <strong>{data.wifi.password || '공개 네트워크'}</strong>
                    </li>
                  </ul>
                </WifiInfo>
              </>
            ) : (
              <div>
                안내된 Wi-Fi에 연결해 주세요
                <Emoji symbol='😮' />
              </div>
            )}
            <br />
            <InfoText text='이후 페이지를 "새로고침"하여 투표에 참여할 수 있습니다!' />
          </div>
        </Warnning>
        {isTest && (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <span style={{ color: 'red', marginTop: 20 }}>
              현재 네트워크 주소: {currentIp}
            </span>
            <br />
            <br />
            <ButtonRed
              text='이 네트워크 주소 허용하기'
              type='button'
              loading={buttonLoading}
              disabled={buttonLoading}
              onClick={handleIpAdd}
            />
          </div>
        )}
      </Form>
    );
  }
  return (
    <form onSubmit={onSubmit}>
      {captchaToken ? (
        <>
          {data.questions.map((question, index) => (
            <Question
              key={index}
              index={index}
              question={question}
              mode='vote'
              disabled={true}
              selectedValue={selectedValue}
              handleSelect={handleSelect}
            />
          ))}
          <Form width='w'>
            <Button
              text={'제출하기'}
              loading={buttonLoading}
              disabled={buttonLoading}
            />
          </Form>
        </>
      ) : (
        <Form width='w'>
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <p>"로봇이 아닙니다" 를 체크하고 투표에 참여하세요!</p>
          </div>
          <CaptchaWrapper>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={'6LeaAMIUAAAAAPzCKCAvLf-jwX9tuEuQgIxLnDLT'}
              onChange={handleCaptcha}
            ></ReCAPTCHA>
          </CaptchaWrapper>
          {isTest && (
            <p style={{ textAlign: 'center', color: 'red', marginTop: 20 }}>
              현재 네트워크 주소: {currentIp}
            </p>
          )}
        </Form>
      )}
    </form>
  );
};
export default VotePresenter;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Warnning = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 20px;
  padding: 10px;
  margin: 10px -10px;
`;

const ButtonRed = styled(Button)`
  margin-left: 10px;
  color: red;
  background: white;
  border: 1px solid red;
  width: 180px;
`;

const WifiInfo = styled.div`
  padding: 0px 15px;
  border: 1px solid #fecf00;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  li {
    margin: 8px 0px;
  }
  strong {
    display: inline-block;
    margin: 8px;
    font-size: 16px;
    font-weight: 600;
  }
`;
