import React from 'react';
import styled from 'styled-components';
import { ArrowRight, ArrowLeft } from './Icons';
import { CircularProgress } from '@material-ui/core';

export default ({ className, text, arrow = 'none', onClick, loading }) => {
  if (loading)
    return (
      <Container className={className}>
        <CircularProgress color='inherit' size='14px' />
      </Container>
    );
  return (
    <Container className={className} onClick={onClick}>
      {arrow === 'left' && <ArrowLeft fill='#1d78cc' />}
      {text}
      {arrow === 'right' && <ArrowRight fill='#1d78cc' />}
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #1d78cc;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  font-weight: 600;
  color: #1d78cc;
  background-color: white;
  :hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
`;
