import { observable, action } from 'mobx';

export default class UserStore {
  @observable username;
  @observable email;
  @observable credit;
  @observable refundableCredit;
  @observable address;
  @observable name;
  @observable tel;
  @observable organ;
  @observable organTel;
  @observable role;
  @observable ips;

  constructor() {
    this.username = '';
    this.email = '';
    this.address = '';
    this.credit = '';
    this.refundableCredit = '';
    this.name = '';
    this.tel = '';
    this.organ = '';
    this.organTel = '';
    this.role = '';
    this.isSelf = '';
    this.ips = [];
  }

  @action
  build = ({
    username,
    email,
    address,
    credit,
    refundableCredit,
    name,
    tel,
    organ,
    organTel,
    isSelf,
    role,
    ips
  }) => {
    this.username = username;
    this.email = email;
    this.address = address;
    this.credit = credit;
    this.refundableCredit = refundableCredit;
    this.name = name;
    this.tel = tel;
    this.organ = organ;
    this.organTel = organTel;
    this.role = role;
    this.isSelf = isSelf;
    this.ips = ips;
  };

  @action
  onChange = e => {
    const { name, value } = e.target;
    if (name === 'username' && value.length > 10) return;
    if (name === 'email' && value.length > 20) return;
    if (name === 'address' && value.length > 50) return;
    if (name === 'name' && value.length > 50) return;
    if (name === 'tel' && value.length > 50) return;
    if (name === 'organ' && value.length > 50) return;
    if (name === 'organTel' && value.length > 50) return;
    this[name] = value;
  };
}
