import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

const Textarea = ({
  className,
  style,
  required,
  value,
  onChange,
  name,
  size,
  placeholder
}) => (
  <Container
    className={className}
    style={style}
    required={required}
    value={value}
    onChange={onChange}
    name={name}
    size={size}
    placeholder={placeholder}
  />
);
export default Textarea;

const Container = styled(TextareaAutosize)`
  padding: 0px 15px;
  padding-top: 8px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #999;
  margin: 10px 0px;
  font-size: ${props => (props.size ? `${props.size}px` : '18px')};
  height: ${props => (props.size ? `${props.size * 2}px` : '35px')};
  width: 100%;
  resize: none;
  &:focus {
    outline: none;
  }
  background-color: white;
  :: placeholder {
    color: #999;
    font-weight: 500;
  }
  margin-bottom: ${props => props.className && '25px'};
`;
