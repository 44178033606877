import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PollinLogo = ({ className, width = 'n', color = 'black', text }) => {
  return (
    <Container
      className={className}
      width={width}
      to={'/'}
      onClick={() => window.fbq('track', 'Search')}
    >
      <img
        src={
          color === 'black'
            ? process.env.REACT_APP_SRC_LOGO_ALPHA
            : process.env.REACT_APP_SRC_LOGO_ALPHA_GREY
        }
        alt='Pollin logo'
      />
      <span>{text}</span>
    </Container>
  );
};
export default PollinLogo;

const Container = styled(Link)`
  display: flex;
  align-items: flex-end;
  max-width: ${props => (props.width === 'n' ? '350px' : '500px')}
  width: 100%;
  color: #757575;
  font-style: italic;
  font-size: 24px;
  font-weight: 800;
  img {
    width: ${props => (props.width === 'n' ? '160px' : '140px')}
    margin-bottom: ${props => (props.width === 'n' ? '-20px' : '-16px')}
    :hover {
      cursor: pointer;
    }
  }
`;
