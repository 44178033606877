import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import CreatePresenter from './Create/CreatePresenter';
import useDemensions from '../Hooks/useDimensions';
import { EDIT_POLL, SEE_POLL, SEE_USER } from '../queries';
import Loader from '../Components/Loader';

const Edit = ({
  history,
  match: {
    params: { id }
  },
  poll,
  user
}) => {
  const { data, loading } = useQuery(SEE_POLL, {
    variables: { id }
  });
  const { data: userData } = useQuery(SEE_USER);
  const [editPollMutation] = useMutation(EDIT_POLL);
  const [dimRef, { width }] = useDemensions({ scrollEvent: false });

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (data && data.seePoll && data.getCurrentIp) {
      poll.build(data.seePoll);
      poll.ips = poll.ips.filter(ip => ip.address !== data.getCurrentIp);
      poll.ips.unshift({ index: 0, address: data.getCurrentIp });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userData) {
      user.build(userData.seeUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const isValidIp = ip => {
    if (ip.split('.').length !== 4) {
      throw Error();
    }
    ip.split('.').forEach(block => {
      if (block < 0 || block > 255) throw Error();
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (poll.questions.length < 1) {
      return toast.error('한 개 이상의 질문을 작성해 주세요.');
    }

    const {
      title,
      description,
      titleImage,
      imageChanged,
      usingLocation,
      usingMessage,
      usingLot
    } = poll;
    const data = {
      id,
      title,
      description,
      usingLocation,
      usingMessage,
      usingLot,
      questions: poll.questions.map(({ title, description, type, items }) => ({
        title,
        description,
        type,
        items: items.map(({ title }) => ({
          title
        }))
      }))
    };

    if (usingLocation) {
      if (poll.ips.length <= 0) {
        return toast.error(
          '위치 제한 기능을 사용하려면 하나 이상의 네트워크 주소를 설정해 주세요.'
        );
      }
      try {
        // ips
        poll.ips.forEach(({ address }) => !isValidIp(address));
        data.ips = poll.ips.map(({ address }) => address);
        // *** wifi
        data.wifi = (({ ssid, password }) => ({ ssid, password }))(poll.wifi);
      } catch {
        return toast.error(
          '네트워크 주소가 유효하지 않습니다. 다시한번 확인해 주세요.'
        );
      }
    }

    try {
      setButtonLoading(true);

      if (titleImage && imageChanged) {
        const axiosEndpoint =
          process.env.REACT_APP_HTTP_ENDPOINT + '/api/upload';
        const formData = new FormData();
        formData.append('file', titleImage);
        const {
          data: { location }
        } = await axios.post(axiosEndpoint, formData);
        data.titleImage = location;
      }

      const {
        data: { editPoll }
      } = await editPollMutation({
        variables: { input: data }
      });

      if (editPoll) {
        toast.success('수정 완료!');
      } else {
        toast.error('잠시 후 다시 시도해 주세요.');
      }
      history.push(`/manage/${id}`);
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        const errorMessage = err.graphQLErrors[0].message;
        if (errorMessage === 'Cant edit poll after starting') {
          toast.error('투표 시작 후에는 항목 수정이 불가합니다.');
          setTimeout(() => history.push(`/manage/${id}`), 2000);
        } else if (errorMessage === 'Too many networks') {
          toast.error('등록하려는 네트워크가 너무 많습니다.');
        } else if (errorMessage === 'Too many questions') {
          toast.error('작성된 질문이 너무 많습니다.');
        }
      } else {
        toast.error('잠시 후 다시 시도해 주세요.');
      }
      setButtonLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <CreatePresenter
      ref={dimRef}
      width={width}
      poll={poll}
      user={user}
      buttonLoading={buttonLoading}
      onSubmit={onSubmit}
      mode='edit'
    />
  );
};

export default inject('poll', 'user')(observer(withRouter(Edit)));
