import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { compose } from 'recompose';
import { toast } from 'react-toastify';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import useInputLimit from '../Hooks/useInputLimit';

import Loader from '../Components/Loader';
import PollinLogo from '../Components/PollinLogo';
import Form from '../Components/Form';
import Wrapper from '../Components/Wrapper';
import Button from '../Components/Button';
import Voting from '../Components/Voting';
import Done from '../Components/Done';
import Textarea from '../Components/Textarea';
import InfoText from '../Components/InfoText';
import Emoji from '../Components/Emoji';

import {
  VOTE,
  ENTER_LOT,
  SEE_LIMITED_POLL,
  SEND_MESSAGE,
  ADD_IP
} from '../queries';
import giftImg from '../img/gift.svg';

const Vote = ({
  history,
  match: {
    params: { id }
  },
  cookies
}) => {
  const pollCookie = cookies.get(id) || {};
  const recaptchaRef = useRef('');
  const [actionNum, setActionNum] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [action, setAction] = useState('vote');
  const [done, setDone] = useState(false);
  const [lotNumber, setLotNumber] = useState(null);
  const [voteMutation] = useMutation(VOTE);
  const [enterLotMutation] = useMutation(ENTER_LOT);
  const [sendMessageMutation] = useMutation(SEND_MESSAGE);
  const [addIpMutation] = useMutation(ADD_IP, { variables: { pollId: id } });
  const { data, loading, error } = useQuery(SEE_LIMITED_POLL, {
    variables: { id }
  });
  const [selectedValue, setSelectedValue] = useState([]);

  const name = useInputLimit('', 20);
  const message = useInputLimit('', 1000);

  const cookieExpires = new Date();
  cookieExpires.setDate(cookieExpires.getDate() + 14);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (error) {
      toast.error('존재하지 않는 투표입니다.');
      history.push('/');
    }
    if (pollCookie.lotNumber) {
      setLotNumber(pollCookie.lotNumber);
    }
    if (pollCookie.vote) {
      setDone(true);
    }
    if (data) {
      const { usingMessage, usingLot } = data.seeLimitedPoll;
      if (usingMessage) setActionNum(p => p + 1);
      if (usingLot) setActionNum(p => p + 1);
    }
  }, [data, error, history, pollCookie.lotNumber, pollCookie.vote]);

  const handleSelect = (e, type) => {
    const { name, value } = e.target;
    if (type === 'ONLY') {
      data.seeLimitedPoll.questions.forEach(question => {
        if (question.id === name) {
          const grouped = question.items.map(({ id }) => id);
          const filtered = selectedValue.filter(
            itemId => !grouped.includes(itemId)
          );
          setSelectedValue([...filtered, value]);
        }
      });
    }
    if (type === 'MULTI') {
      setSelectedValue(prev => {
        if (prev.includes(value)) {
          return prev.filter(v => v !== value);
        } else {
          return [...prev, value];
        }
      });
    }
  };

  const handleCaptcha = () => {
    setCaptchaToken(recaptchaRef.current.getValue());
  };

  const handleEnterLot = async () => {
    try {
      setButtonLoading(true);
      const {
        data: { enterLot }
      } = await enterLotMutation({
        variables: {
          pollId: id
        }
      });

      if (enterLot) {
        if (!data.seeLimitedPoll.isTest) {
          cookies.set(
            id,
            Object.assign({}, pollCookie, { lotNumber: enterLot }),
            {
              path: '/',
              expires: cookieExpires
            }
          );
        }
        setLotNumber(enterLot);
      } else {
        toast.error('지정된 장소에서 참여해주세요.');
      }
    } catch (err) {
      toast.error('잠시 후 다시 시도해 주세요.');
    } finally {
      setButtonLoading(false);
    }
  };

  const handleVote = async e => {
    e.preventDefault();
    if (!captchaToken) {
      return toast.error(`"로봇이 아닙니다."를 체크해 주세요.`);
    }
    if (!selectedValue) {
      return toast.error('투표할 항목을 선택해주세요.');
    }

    try {
      setButtonLoading(true);
      const {
        data: { vote }
      } = await voteMutation({
        variables: {
          id: id,
          itemIds: selectedValue,
          captchaToken
        }
      });

      if (vote === true) {
        if (!data.seeLimitedPoll.isTest) {
          cookies.set(id, Object.assign({}, pollCookie, { vote }), {
            path: '/',
            expires: cookieExpires
          });
        }
        setDone(true);
      } else {
        toast.error('지정된 장소에서 참여해주세요.');
        setButtonLoading(false);
      }
    } catch (err) {
      if (err.graphQLErrors[0]) {
        const errorMessage = err.graphQLErrors[0].message;
        if (errorMessage === 'Poll has closed') {
          toast.error('투표가 이미 종료됐습니다.');
        } else if (errorMessage === 'Failed to verify captcha') {
          toast.error(
            '보안문자 유효기간이 만료됐습니다. 페이지 새로고침 후 다시 시도해 주세요.'
          );
        }
      } else {
        toast.error('잠시 후 다시 시도해 주세요.');
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSendMessage = async e => {
    e.preventDefault();
    if (!message.value) toast.error('메세지를 입력해 주세요.');

    try {
      setButtonLoading(true);
      const {
        data: { sendMessage }
      } = await sendMessageMutation({
        variables: { pollId: id, name: name.value, message: message.value }
      });
      if (sendMessage) {
        toast.success('메세지 보내기 완료');
        message.setValue('');
      }
    } catch {
      toast.error('잠시후 다시 시도해 주세요');
    } finally {
      setButtonLoading(false);
    }
  };

  const handleIpAdd = async () => {
    try {
      setButtonLoading(true);
      const {
        data: { addIp }
      } = await addIpMutation();
      if (addIp) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setButtonLoading(false);
    }
  };

  if (loading || error) return <Loader />;
  return (
    <VoteHomeWrapper>
      <Helmet title='Vote | Pollin' />
      <PollinLogo color='grey' width='w' />
      <Form width='w'>
        {data.seeLimitedPoll.isTest && (
          <p style={{ color: 'red' }}>
            빨간색 텍스트는 테스트모드에서만 표시됩니다.
          </p>
        )}
        <TitleText>{data.seeLimitedPoll.title} </TitleText>
        <DescriptionText>{data.seeLimitedPoll.description} </DescriptionText>
        {data.seeLimitedPoll.titleImage && (
          <img
            className='title-img'
            src={data.seeLimitedPoll.titleImage}
            alt='타이틀 이미지'
          />
        )}
      </Form>
      <ActionSelectForm width='w' actionNum={actionNum}>
        <div
          className={`select-btn-wrap ${action === 'vote' ? 'selected' : ''}`}
        >
          <button onClick={() => setAction('vote')}>투표</button>
        </div>
        {data && data.seeLimitedPoll.usingMessage && (
          <div
            className={`select-btn-wrap ${action === 'qna' ? 'selected' : ''}`}
          >
            <button
              className={action === 'qna' ? 'selected' : ''}
              onClick={() => setAction('qna')}
            >
              QnA
            </button>
          </div>
        )}
        {data && data.seeLimitedPoll.usingLot && (
          <div
            className={`select-btn-wrap ${action === 'lot' ? 'selected' : ''}`}
          >
            <button
              className={action === 'lot' ? 'selected' : ''}
              onClick={() => setAction('lot')}
            >
              추첨
            </button>
          </div>
        )}
      </ActionSelectForm>
      {action === 'vote' &&
        (done ? (
          <Done isTest={data.seeLimitedPoll.isTest} />
        ) : (
          <Voting
            recaptchaRef={recaptchaRef}
            selectedValue={selectedValue}
            buttonLoading={buttonLoading}
            data={data.seeLimitedPoll}
            onSubmit={handleVote}
            handleSelect={handleSelect}
            captchaToken={captchaToken}
            handleCaptcha={handleCaptcha}
            currentIp={data.getCurrentIp}
            isValidIp={data.seeLimitedPoll.checkIp}
            isTest={data.seeLimitedPoll.isTest}
            handleIpAdd={handleIpAdd}
          />
        ))}

      {action === 'qna' && (
        <form onSubmit={handleSendMessage}>
          <Form className='Qna-form' width='w'>
            <div className='Qna-row'>
              <span>이름</span>
              <Textarea
                className='Qna-name textarea'
                placeholder='익명'
                {...name}
              />
            </div>
            <div className='Qna-row'>
              <span className='Qna-message-label'>메세지</span>
              <Textarea
                className='Qna-message textarea'
                required={true}
                {...message}
              />
            </div>
            <Button
              className='Qna-send-btn'
              type='submit'
              text='보내기'
              loading={buttonLoading}
            />
          </Form>
        </form>
      )}

      {action === 'lot' && (
        <Form className='lot-form' width='w'>
          {lotNumber ? (
            <div className='lot-done-wrap'>
              <p className='done-text'>응모 완료!</p>
              <p className='lot-number'>{lotNumber}</p>
              <div>
                <InfoText text='추첨 번호를 확인해 주세요' size='16px' />
                <Emoji symbol='😀' size='16px' />
              </div>
            </div>
          ) : (
            <>
              <ReactSVG className='gift-img' src={giftImg} />
              <Button
                text='응모하기'
                loading={buttonLoading}
                disabled={buttonLoading}
                onClick={handleEnterLot}
              />
            </>
          )}
        </Form>
      )}
    </VoteHomeWrapper>
  );
};
export default compose(withRouter, withCookies)(Vote);

const VoteHomeWrapper = styled(Wrapper)`
  .title-img {
    width: 100%;
  }
  .Menu-wrap {
    display: flex;
    > button {
      margin: 2px;
    }
  }
  .textarea {
    flex: 1;
    margin: 0px;
    font-size: 16px;
  }
  .Qna-form {
    display: flex;
    flex-direction: column;
  }
  .Qna-row {
    display: flex;
    align-items: center;
    margin: 12px 0px;
    > span {
      width: 45px;
      text-align: end;
      margin-right: 8px;
      color: #999;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .Qna-message {
    height: 100px;
    border: 1px solid #999;
  }
  .Qna-message-label {
    padding-top: 8px;
    align-self: start;
  }
  .Qna-send-btn {
    align-self: flex-end;
    width: 80px;
  }
  .lot-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    .gift-img {
      display: flex;
      align-items: center;
      width: 50%;
      min-height: 200px;
      margin-bottom: 20px;
      height: auto;
      svg {
        width: 100%;
        height: auto;
      }
    }
    button {
      width: 50%;
    }
    h1 {
      align-self: flex-start;
    }
  }
  .lot-done-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .done-text {
      font-size: 22px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    .lot-number {
      font-size: 80px;
      padding: 30px 0px;
    }
  }
`;

const TitleText = styled.div`
  font-size: 26px;
  font-weight: 800;
`;

const DescriptionText = styled.div`
  fontweight: 500;
  color: '#999';
  padding-top: 10px;
  padding-bottom: 20px;
`;

const ActionSelectForm = styled(Form)`
  display: flex;
  height: 60px;
  margin-bottom: 5px;
  background: none;
  border: none;
  padding: 0;
  .select-btn-wrap {
    flex: 1;
    border: 1px solid #e6e6e6;
    button {
      width: 100%;
      height: 60px;
      border: none;
      outline: 0;
      color: #999;
      background-color: white;
      font-size: 16px;
      font-weight: 800;
    }
    :nth-child(2) {
      margin: 0px 4px;
      margin-right: ${props => (props.actionNum === 3 ? '4px' : '0px')};
    }
  }
  .selected {
    button {
      color: #262626;
      background-color: white;
    }
    height: 66px;
    background-color: white;
    border-bottom: 0;
    z-index: 5;
  }
`;
