import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Button from '../Components/Button';
import {
  REQUEST_CHARGE,
  SEE_MY_CHARGE_REQUEST,
  SEE_USER,
  COMFIRM_PAYMENT,
  REQUEST_KSNET_PAYMENT,
  QUERY_PAY_RESULT
} from '../queries';
import PollinLogo from '../Components/PollinLogo';
import Wrapper from '../Components/Wrapper';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import useInput from '../Hooks/useInput';
import { CircularProgress, Radio } from '@material-ui/core';

const IMP = window.IMP;
IMP.init('imp98710823');

const Charging = ({
  history,
  match: {
    params: { username }
  },
  user
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [payMethod, setPayMethod] = useState(null);
  const depositNameInput = useInput('');
  const depositNumberInput = useInput('');
  const depositTelInput = useInput(user.tel || '');
  const chargeAmount = useInput('0');
  const [isRefundMode, setIsRefundMode] = useState(false);
  // ***
  const [confirmPaymentMutation] = useMutation(COMFIRM_PAYMENT);
  const [requestKsnetPaymentMutation] = useMutation(REQUEST_KSNET_PAYMENT);
  const [requestChargeMutation] = useMutation(REQUEST_CHARGE, {
    refetchQueries: [{ query: SEE_MY_CHARGE_REQUEST }]
  });
  const { data, loading } = useQuery(SEE_MY_CHARGE_REQUEST);
  const [payResultMutation] = useMutation(QUERY_PAY_RESULT);
  const { data: userData, loading: userDataLoading } = useQuery(SEE_USER, {
    variables: { username }
  });

  /**
   * username 없이 라우팅 되는 경우는 계좌 입금으로 크레딧 충전
   * 외부에 이 루트는 숨기고 계좌 이체 필요한 고객에만 안내
   */
  const usingAccount = !username;

  useEffect(() => {
    if (userData && userData.seeUser) {
      user.build(userData.seeUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, data]);

  const requestPayKsnet = async () => {
    const redirectUrl = process.env.REACT_APP_HTTP_ENDPOINT;
    const orderNo = 'pollin_' + Date.now();
    const prodName = '폴린(Pollin)';
    const userId = 'dumm';
    const userName = depositNameInput.value;
    const userEmail = user.email;
    const userPhone = depositTelInput.value;
    const companyId = 'dumm';
    const companyName = depositNameInput.value;
    const url = `https://pay.lpin.io/requestCcPayment.html?redirectUrl=${redirectUrl}&prodName=${prodName}&sndOrdernumber=${orderNo}&userId=${userId}&companyId=${companyId}&companyName=${companyName}&userName=${userName}&userPhone=${userPhone}&userEmail=${userEmail}&sndGoodname=폴린크레딧&sndAmount=${commaStringToNumber(
      chargeAmount.value
    )}`;

    var isNum = /^[0-9]+$/;
    if (!isNum.test(commaStringToNumber(chargeAmount.value))) {
      window.alert('금액이 올바르지 않습니다.');
      return;
    }

    try {
      setButtonLoading(true);
      console.log(orderNo);
      const {
        data: { requestKsnetPayment }
      } = await requestKsnetPaymentMutation({
        variables: { merchantUid: orderNo }
      });

      if (requestKsnetPayment) {
        // *** 결제 결과 수신 여부 폴링
        const intervalId = setInterval(async () => {
          const {
            data: { queryPayResult }
          } = await payResultMutation({
            variables: { paymentId: requestKsnetPayment }
          });
          if (queryPayResult) {
            clearInterval(intervalId);
            setButtonLoading(false);
            window.location.reload();
          }
        }, 3000);
        setTimeout(() => {
          clearInterval(intervalId);
        }, 300000); // 결제 타임아웃 5분
      }

      // *** 페이 서버 결제 페이지 호출
      window.open(
        url,
        'paymentWindow',
        'width=560, height=637, menubar=no, location=1'
      );
    } catch (err) {
      console.log(err);
    }
  };

  const requestPayImport = () => {
    const amount = commaStringToNumber(chargeAmount.value);
    const merchantUid = 'pollin_' + Date.now();
    IMP.request_pay(
      {
        pg: 'kakaopay',
        pay_method: 'card',
        merchant_uid: merchantUid,
        name: '폴린 크레딧 충전',
        amount,
        buyer_name: depositNameInput.value,
        buyer_tel: depositTelInput.value,
        m_redirect_url: document.location.href
      },
      async function(rsp) {
        const {
          data: { confirmPayment }
        } = await confirmPaymentMutation({
          variables: {
            merchantUid,
            amount
          }
        });
        if (confirmPayment) {
          alert(`결제가 완료되었습니다.\n결제 금액: ${rsp.paid_amount}`);
          window.location.reload();
          user.onCreditChange(rsp.paid_amount);
        } else {
          alert('결제에 실패하였습니다.\n실패 사유: ' + rsp.error_msg);
        }
      }
    );
  };

  const displayDate = tz => {
    const dt = new Date(tz);
    let month = dt.getMonth() + 1;
    let date = dt.getDate();
    if (month < 10) month = '0' + month;
    if (date < 10) date = '0' + date;

    return dt.getFullYear() + '-' + month + '-' + date;
  };
  const displayTime = tz => {
    const dt = new Date(tz);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
  };

  const resetInput = () => {
    depositNameInput.setValue('');
    depositNumberInput.setValue('');
    depositTelInput.setValue('');
    chargeAmount.setValue('0');
  };

  const numberToCommaString = n => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const commaStringToNumber = s => {
    return s.split(',').join('') * 1;
  };

  const handleAmountBtnClick = e => {
    if (e === 0) {
      chargeAmount.setValue(numberToCommaString(user.refundableCredit));
    } else {
      chargeAmount.setValue(
        numberToCommaString(
          commaStringToNumber(chargeAmount.value) + e.target.value * 1
        )
      );
    }
  };

  const handleModeChange = () => {
    setIsRefundMode(p => !p);
    chargeAmount.setValue('0');
    if (!user.refundableCredit) {
    }
  };

  const handleClearBtnClick = () => {
    chargeAmount.setValue('0');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!isRefundMode) {
      window.fbq('track', 'AddPaymentInfo');
    }
    const amount = commaStringToNumber(chargeAmount.value);
    if (isRefundMode) {
      if (amount > user.refundableCredit) {
        return toast.error('입력한 금액이 환불 가능액을 넘습니다');
      }
    } else {
      if (amount < 10) {
        return toast.error('최소 충전금액은 10원 입니다');
      }
      if (!payMethod) {
        return toast.error('결제 수단을 선택해 주세요');
      }
    }
    if (!depositTelInput | !depositTelInput | !amount | (amount < 0)) {
      return toast.error('신청 정보를 다시 확인해 주세요');
    }

    if (isRefundMode | usingAccount) {
      // *** 충전/환전 신청 (admin에서 확인 가능)
      try {
        setButtonLoading(true);
        const requestChargeMutationData = {
          amount: commaStringToNumber(chargeAmount.value),
          depositName: depositNameInput.value,
          depositTel: depositTelInput.value,
          depositNumber: depositNumberInput.value,
          isRefund: isRefundMode
        };
        const {
          data: { requestCharge }
        } = await requestChargeMutation({
          variables: { input: requestChargeMutationData }
        });
        if (requestCharge) {
          isRefundMode
            ? toast.success('환불 신청이 완료됐습니다')
            : toast.success('충전 신청이 완료됐습니다');
          if (!isRefundMode) {
            window.fbq('track', 'Purchase', {
              value: chargeAmount.value,
              currency: 'KRW'
            });
          }
        }
        resetInput();
      } catch (err) {
        console.log(err);
      } finally {
        setButtonLoading(false);
      }
    } else {
      if (payMethod === 'kakao') {
        requestPayImport();
      } else {
        requestPayKsnet();
      }
    }
  };

  return (
    <Wrapper>
      <PollinLogo color='grey' />
      <ChargingForm>
        <form onSubmit={handleSubmit}>
          <h2>{isRefundMode ? '크레딧 환불' : '크레딧 충전'}</h2>
          <div className='toggle-refund-btn'>
            <button
              type='button'
              className='Refund-mode-btn'
              onClick={handleModeChange}
            >
              {isRefundMode ? '충전' : '환불 신청'}
            </button>
          </div>
          <div className='Amount-wrap'>
            <h3>{isRefundMode ? '환불금액' : '충전금액'}</h3>
            <input
              className='Amount-input'
              type='numeric'
              onFocus={() =>
                chargeAmount.setValue(commaStringToNumber(chargeAmount.value))
              }
              onBlur={() =>
                chargeAmount.setValue(numberToCommaString(chargeAmount.value))
              }
              required={true}
              value={chargeAmount.value}
              onChange={chargeAmount.onChange}
            />

            {isRefundMode ? (
              <div
                style={{
                  width: '100%',
                  textAlign: 'end',
                  padding: '0px 10px',
                  color: '#999'
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAmountBtnClick(0)}
                >
                  환불 가능 크레딧:
                </span>
                {userDataLoading && !user.refundableCredit ? (
                  <CircularProgress color='inherit' size='12px' />
                ) : (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleAmountBtnClick(0)}
                  >
                    {user.refundableCredit}
                  </span>
                )}
              </div>
            ) : (
              <div className='Amount-btn-wrap'>
                <button type='button' onClick={handleClearBtnClick}>
                  초기화
                </button>
                <div className='Button-between-space' />
                <button
                  type='button'
                  value={50000}
                  onClick={handleAmountBtnClick}
                >
                  +5만원
                </button>
                <button
                  type='button'
                  value={100000}
                  onClick={handleAmountBtnClick}
                >
                  +10만원
                </button>
                <button
                  type='button'
                  value={150000}
                  onClick={handleAmountBtnClick}
                >
                  +15만원
                </button>
              </div>
            )}
          </div>
          <div className='Info-wrap'>
            <div className='Item-title-wrap'>
              <span>
                <strong>{isRefundMode ? '예금주' : '이름'}</strong>(기관명)
              </span>
            </div>
            <input
              value={depositNameInput.value}
              onChange={depositNameInput.onChange}
              required={true}
            />
            {isRefundMode && (
              <>
                <div className='Item-title-wrap'>
                  <span>
                    <strong>계좌번호</strong>
                  </span>
                </div>
                <input
                  value={depositNumberInput.value}
                  onChange={depositNumberInput.onChange}
                  required={true}
                />
              </>
            )}

            <div className='Item-title-wrap'>
              <span>
                <strong>연락처</strong>(담당자 연락처)
              </span>
            </div>
            <input
              placeholder='02-123-4567'
              value={depositTelInput.value}
              onChange={depositTelInput.onChange}
              required={true}
            />
          </div>
          {!isRefundMode && (
            <div className='pay-method-select'>
              <div>
                <Radio
                  size='small'
                  checked={payMethod === 'kakao'}
                  onChange={() => setPayMethod('kakao')}
                />
                <span>카카오페이(개인)</span>
              </div>
              <div>
                <Radio
                  size='small'
                  checked={payMethod === 'ksnet'}
                  onChange={() => setPayMethod('ksnet')}
                />
                <span>카드(법인)</span>
              </div>
            </div>
          )}

          <Button
            className={isRefundMode ? 'refund-btn' : ''}
            type='submit'
            text={
              isRefundMode ? '환불 신청' : usingAccount ? '충전 신청' : '결제'
            }
            loading={buttonLoading}
          />
        </form>
      </ChargingForm>
      <ChargingHistoryForm>
        <h3>크레딧 변동 내역</h3>
        {loading ? (
          <div className='Circular-wrap'>
            <CircularProgress color='inherit' size='24px' />
          </div>
        ) : (
          <>
            <HistoryHeader>
              <span className='History-header datetime'>일시</span>
              <span className='History-header amount'>수량</span>
              <span className='History-header Cause'>구분</span>
            </HistoryHeader>
            {userData &&
              userData.seeUser.creditHistories.map(history => (
                <HistoryRow key={history.id}>
                  <span className='History-row datetime'>
                    {displayDate(history.createdAt)}
                    <br />
                    {displayTime(history.createdAt)}
                  </span>
                  <span className={`History-row amount ${history.type}`}>
                    {history.type === 'INC' && '+'}
                    {numberToCommaString(history.amount)}
                  </span>
                  <span className='History-row Cause'>{history.cause}</span>
                </HistoryRow>
              ))}
            {userData && userData.seeUser.creditHistories === 0 && (
              <div className='History-none'>내역이 없습니다</div>
            )}
          </>
        )}
      </ChargingHistoryForm>
    </Wrapper>
  );
};
export default inject('user')(withRouter(observer(Charging)));

const Form = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: white;
  width: 350px;
  min-width: 320px;
  max-width: 95vw;
  padding: 30px;
  color: #262626;
  h2 {
    font-size: 22px;
    font-weight: 600;
  }
  h3 {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
  }
  strong {
    font-size: 16px;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 0px 15px;
    border: 1px solid #999;
    border-radius: 4px;
    margin-bottom: 4px;
    font-size: 16px;
  }
`;
const ChargingForm = styled(Form)`
  .Amount-input {
    border: 1px solid #262626;
  }
  .Amount-wrap,
  .Info-wrap {
    margin-top: 30px;
  }
  .refund-btn {
    margin-top: 30px;
  }
  .Amount-btn-wrap {
    display: flex;
    button {
      flex: 1;
      height: 30px;
      border: 1px solid #999;
      outline: 0;
      border-radius: 4px;
      font-weight: 500;
      color: #262626;
      background-color: white;
      text-align: center;
      padding: 7px 0px;
      font-size: 12px;
      cursor: pointer;
      :not(:first-child) {
        margin-left: 4px;
      }
    }
  }
  .Button-between-space {
    width: 40px;
  }
  .Item-title-wrap {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .toggle-refund-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .Refund-mode-btn {
    background: white;
    border: none;
    color: #67adf3;
    font-size: 12px;
    outline: 0;
    cursor: pointer;
  }
  .pay-method-select {
    display: flex;
    margin-top: 20px;
    span {
      font-weight: 600;
    }
  }
`;

const ChargingHistoryForm = styled(Form)`
  word-wrap: break-word;

  .INC {
    color: #d84f24;
  }
  .DEC {
    color: #2159ad;
  }

  .datetime {
    width: 100px;
  }
  .amount {
    flex: 2;
  }
  .Cause {
    flex: 2;
  }

  .History-none {
    text-align: center;
    font-weight: 600;
    color: #999;
  }
  .Circular-wrap {
    display: flex;
    justify-content: center;
  }
`;

const HistoryHeader = styled.div`
  display: flex;
  padding-bottom: 8px;
  margin: 20px 0px 10px 0px;
  border-bottom: 1px solid #999;
  align-items: center;
  text-align: center;
  font-weight: 600;
`;

const HistoryRow = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px;
  border-radius: 4px;
  background-color: white;
  text-align: center;
  span {
    width: 60px;
  }
  button {
    width: 50px;
    height: 30px;
    font-size: 12px;
  }
`;
