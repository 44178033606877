import React from 'react';
import styled from 'styled-components';

const FatText = ({ text, className, size, color = '#262626' }) => (
  <Container className={className} size={size} color={color}>
    {text}
  </Container>
);

const Container = styled.span`
flex: 1;
vertical-align: middle;
  color: ${props => props.color}
  font-size: ${props => props.size || '14px'}
  font-weight: 600;
`;

export default FatText;
