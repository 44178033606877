import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { toast } from 'react-toastify';
import useDemensions from '../../Hooks/useDimensions';
import Loader from '../../Components/Loader';
import ManagePresenter from './ManagePresenter';
import useInput from '../../Hooks/useInput';

import {
  TOGGLE_USING_LOCATION,
  SEE_POLL,
  TOGGLE_OPEN,
  FINISH_TEST
} from '../../queries';

const ManageContainer = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [usingLocation, setUsingLocation] = useState(false);
  const [usingMessage, setUsingMessage] = useState(false);
  const [usingLot, setUsingLot] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [dimRef, { width }] = useDemensions({ scrollEvent: false });
  const shareLink = useInput(
    `${document.location.href.split('#')[0]}#/vote/${id}`
  );
  const [toggleOpenMutation] = useMutation(TOGGLE_OPEN);
  const [toggleUsingLocationMutation] = useMutation(TOGGLE_USING_LOCATION);
  const [finishTestMutation] = useMutation(FINISH_TEST, {
    variables: { pollId: id }
  });
  const { data, loading, error } = useQuery(SEE_POLL, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (error) {
      toast.error('존재하지 않는 투표입니다.');
      history.push('/');
    }
  });

  useEffect(() => {
    if (data && data.seePoll) {
      setIsOpen(data.seePoll.isOpen);
      setIsTest(data.seePoll.isTest);
      setUsingLocation(data.seePoll.usingLocation);
      setUsingMessage(data.seePoll.usingMessage);
      setUsingLot(data.seePoll.usingLot);
    }
  }, [data]);

  const downloadQR = () => {
    const canvas = document.getElementById('qrcode');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'pollin-QR.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleToggleOpen = async () => {
    const prevIsOpen = isOpen;
    setIsOpen(!prevIsOpen);
    try {
      await toggleOpenMutation({
        variables: { id, isOpen: !isOpen }
      });
    } catch {
      toast.error('지금은 변경할 수 없습니다. 잠시후 다시 시도해주세요.');
      setIsOpen(prevIsOpen);
    }
  };

  const handleToggleUsingLocation = async () => {
    const prevUsingLocation = usingLocation;
    setUsingLocation(!prevUsingLocation);
    try {
      await toggleUsingLocationMutation({
        variables: { id, usingLocation: !usingLocation }
      });
    } catch {
      toast.error('지금은 변경할 수 없습니다. 잠시후 다시 시도해주세요.');
      setUsingLocation(prevUsingLocation);
    }
  };

  const handleFinishTest = async () => {
    if (
      window.confirm(
        '테스트를 종료하고 투표를 시작하시겠습니까?\n투표 이력이 초기화되고, 더는 투표 항목 수정이 불가합니다.'
      )
    ) {
      try {
        setButtonLoading(true);
        const {
          data: { finishTest }
        } = await finishTestMutation();

        if (finishTest) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  if (loading || error) return <Loader />;
  return (
    data.seePoll && (
      <ManagePresenter
        ref={dimRef}
        history={history}
        data={data.seePoll}
        isOpen={isOpen}
        isTest={isTest}
        handleFinishTest={handleFinishTest}
        handleToggleOpen={handleToggleOpen}
        usingLocation={usingLocation}
        handleToggleUsingLocation={handleToggleUsingLocation}
        usingMessage={usingMessage}
        usingLot={usingLot}
        downloadQR={downloadQR}
        shareLink={shareLink}
        buttonLoading={buttonLoading}
        id={id}
        width={width}
      />
    )
  );
};

export default withRouter(ManageContainer);
