import React from 'react';
import styled from 'styled-components';

const Box = ({ children, className, width = 'n' }) => (
  <Container className={className} width={width}>
    {children}
  </Container>
);
export default Box;

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: white;
  width: ${props => (props.width === 'n' ? '350px' : '500px')};
  min-width: 320px;
  max-width: 95vw;
`;
