import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dot, Search, Xmark } from '../Components/Icons';
import {
  SEE_ALL,
  SEARCH_BY_HOST,
  SEARCH_BY_TITLE,
  ADMIN_LOGIN
} from '../queries';
import PollinLogo from '../Components/PollinLogo';
import Wrapper from '../Components/Wrapper';
import Loader from '../Components/Loader';
import { toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import Input from '../Components/Input';
import IconButton from '../Components/IconButton';
import useInput from '../Hooks/useInput';
import Pagination from 'react-js-pagination';
import Button from '../Components/Button';
import Form from '../Components/Form';
import { LOCAL_LOG_IN } from '../queries';

const useStyles = makeStyles({
  root: {
    paddingTop: '5px',
    paddingBottom: '5px'
  }
});

const Admin = ({ history }) => {
  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState(false);
  // *** data print & loading, pagination
  const ROWS_PER_PAGE = 10;
  const [allData, setAllData] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const { data, loading, error } = useQuery(SEE_ALL, {
    fetchPolicy: 'network-only'
  });
  // ** search
  const searchTerm = useInput('');
  const [searchType, setSearchType] = useState('HOST');
  const [searchByHostMutation] = useMutation(SEARCH_BY_HOST);
  const [searchByTitleMutation] = useMutation(SEARCH_BY_TITLE);
  // *** admin auth
  const email = useInput('');
  const adminSecret = useInput('');
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [adminLoginMutation] = useMutation(ADMIN_LOGIN);
  const [localLogInMutation] = useMutation(LOCAL_LOG_IN);

  useEffect(() => {
    if (error) {
      setIsAuthenticated(false);
    }
  }, [error]);

  useEffect(() => {
    if (data && data.seeAll) {
      setAllData(data.seeAll);
      setPrintData(data.seeAll.slice(0, ROWS_PER_PAGE));
    }
  }, [data]);

  const displayDate = tz => {
    const dt = new Date(tz);
    return dt.getFullYear() + '/' + (dt.getMonth() + 1) + '/' + dt.getDate();
  };

  const handleSelectChange = async e => {
    setSearchType(e.target.value);
  };

  const handleSearchSubmit = async e => {
    e.preventDefault();
    if (searchTerm.value === '') return;

    try {
      setButtonLoading(true);
      if (searchType === 'HOST') {
        const {
          data: { searchByHost }
        } = await searchByHostMutation({
          variables: { username: searchTerm.value }
        });
        setAllData(searchByHost);
        setPrintData(searchByHost.slice(0, ROWS_PER_PAGE));
      } else {
        const {
          data: { searchByTitle }
        } = await searchByTitleMutation({
          variables: { title: searchTerm.value }
        });
        setAllData(searchByTitle);
        setPrintData(searchByTitle.slice(0, ROWS_PER_PAGE));
      }
    } catch {
      toast.error('잠시 후 다시 시도해 주세요.');
    } finally {
      setButtonLoading(false);
    }
  };

  const handlePageChange = pageNumber => {
    const start = (pageNumber - 1) * ROWS_PER_PAGE;
    setPrintData(allData.slice(start, start + ROWS_PER_PAGE));
    setActivePage(pageNumber);
  };

  const handleLoginSubmit = async e => {
    e.preventDefault();
    setSearchType(e.target.value);
    setButtonLoading(true);
    const {
      data: { adminLogin }
    } = await adminLoginMutation({
      variables: { email: email.value, adminSecret: adminSecret.value }
    });

    if (adminLogin !== null) {
      localLogInMutation({ variables: { token: adminLogin } });
      window.location.reload();
    } else {
      toast.error('secret을 다시 확인하세요.');
    }
    setButtonLoading(false);
  };

  if (loading) return <Loader />;
  if (!isAuthenticated) {
    return (
      <Wrapper>
        <PollinLogo />
        <Form>
          <form onSubmit={handleLoginSubmit}>
            <Input placeholder={'e-mail'} type='email' {...email} />
            <Input placeholder={'Secret'} type='password' {...adminSecret} />
            <Button
              loading={buttonLoading}
              text={'로그인'}
              disabled={buttonLoading}
            />
          </form>
        </Form>
      </Wrapper>
    );
  }
  return (
    <AdminWrapper>
      <AdminPollinLogo color='grey' width='w' text='Admin' />
      <AdminForm width='w'>
        {loading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 150
            }}
          >
            <CircularProgress color='inherit' size='24px' />
          </div>
        )}

        <div style={{ textAlign: 'end', marginBottom: 15 }}>
          <NavLink to='/admin/charging'>충전 신청 관리</NavLink>
          <NavLink to='/admin/refund'>환불 신청 관리</NavLink>
        </div>
        <SearchContainer onSubmit={handleSearchSubmit}>
          <FormControl variant='outlined' disabled={false}>
            <Select
              name='type'
              classes={{ root: classes.root }}
              value={searchType}
              onChange={handleSelectChange}
              displayEmpty
            >
              <MenuItem value='HOST'>
                <span style={{ fontSize: 12 }}>호스트</span>
              </MenuItem>
              <MenuItem value='TITLE'>
                <span style={{ fontSize: 12 }}>타이틀</span>
              </MenuItem>
            </Select>
          </FormControl>
          <Input size='14px' {...searchTerm} />

          {buttonLoading ? (
            <div
              style={{
                width: 36,
                height: 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress color='inherit' size='16px' />
            </div>
          ) : (
            <IconButton onClick={handleSearchSubmit} disabled={buttonLoading}>
              <Search />
            </IconButton>
          )}
          <IconButton
            className='Admin-search-xmark'
            onClick={() => {
              searchTerm.setValue('');
              setPrintData(data.seeAll);
            }}
            disabled={buttonLoading}
          >
            <Xmark size='16' />
          </IconButton>
        </SearchContainer>

        <Header>
          <span style={{ width: 40 }}>오픈</span>
          <span style={{ width: 40 }}>위치 제한</span>
          <span style={{ flex: 1 }}>제목</span>
          <span style={{ width: 100 }}>호스트</span>
          <span style={{ width: 70 }}>생성일자</span>
        </Header>
        {printData && (
          <>
            {printData.map(poll => (
              <ItemRows key={poll.id}>
                <span style={{ width: 40, textAlign: 'center' }}>
                  <Dot fill={poll.isOpen ? '#0FBE00' : '#CD4141'} />
                </span>
                <span style={{ width: 40, textAlign: 'center' }}>
                  <Dot fill={poll.usingLocation ? '#0FBE00' : '#CD4141'} />
                </span>
                <span
                  className='Admin-title-text'
                  style={{
                    flex: 1,
                    textAlign: 'left',
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: 30
                  }}
                  onClick={() => history.push(`/manage/${poll.id}`)}
                >
                  {poll.title}
                </span>
                <span
                  className='Admin-username-text'
                  style={{ width: 100, flexShrink: 0, textAlign: 'center' }}
                  onClick={() => history.push(`/profile/${poll.host.username}`)}
                >
                  {poll.host.username}
                </span>
                <span style={{ width: 70, flexShrink: 0, textAlign: 'center' }}>
                  {displayDate(poll.createdAt)}
                </span>
              </ItemRows>
            ))}
          </>
        )}
        {printData && printData.length === 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 150
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#999',
                textAlign: 'center'
              }}
            >
              결과가 없습니다.
            </span>
          </div>
        )}
      </AdminForm>
      {allData && allData.length > ROWS_PER_PAGE && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ROWS_PER_PAGE}
          totalItemsCount={allData.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      )}
    </AdminWrapper>
  );
};
export default withRouter(Admin);

const AdminWrapper = styled(Wrapper)`
  .undefined {
    color: black !important;
  }
  li {
    font-size: 16px;
    margin: 0px 10px;
    display: inline-block;
  }
`;

const AdminPollinLogo = styled(PollinLogo)`
  min-width: 700px;
  max-width: 700px;
  word-break: keep-all;
`;

const AdminForm = styled.div`
  min-width: 700px;
  max-width: 700px;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  li {
    display: inline-block;
    margin: 0px 4px;
    color: #3897f0;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const SearchContainer = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  input {
    margin-left: 8px;
    width: 300px;
    height: 30px;
  }
  .Admin-search-xmark {
    svg {
      margin: 10px 0px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  span {
    text-align: center;
    font-weight: 600;
  }
`;

const ItemRows = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  svg {
    margin: 5px 10px 5px 5px;
    :last-child {
      margin-right: -5px;
    }
  }
  .Admin-title-text,
  .Admin-username-text {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const NavLink = styled(Link)`
  margin: 0px 10px;
  text-decoration: underline;
`;
