import React from 'react';
import styled from 'styled-components';

const Form = ({ children, className, width = 'n' }) => (
  <Container className={className} width={width}>
    {children}
  </Container>
);
export default Form;

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: white;
  width: ${props => (props.width === 'n' ? '350px' : '500px')};
  min-width: 320px;
  max-width: 95vw;
  padding: 30px;
  margin-bottom: 8px;
  form {
    width: 100%;
    input {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    button {
      margin-top: 10px;
    }
  }
  h1 {
    font-size: 22px;
    font-weight: 800;
    color: #262626;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media (max-width: 520px) {
    padding: ${props => (props.width === 'n' ? '30px' : '30px 15px')};
  }
`;
