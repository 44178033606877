import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { Dot } from '../Components/Icons';
import { ADMIN_LOGIN, SEE_ALL_CHARGE_REQUEST } from '../queries';
import PollinLogo from '../Components/PollinLogo';
import Wrapper from '../Components/Wrapper';
import Loader from '../Components/Loader';
import { toast } from 'react-toastify';
import Input from '../Components/Input';
import useInput from '../Hooks/useInput';
import Pagination from 'react-js-pagination';
import Button from '../Components/Button';
import Form from '../Components/Form';
import { LOCAL_LOG_IN, CONFIRM_CHARGE_REQUEST } from '../queries';

const AdminCharging = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  // *** data print & loading, pagination
  const ROWS_PER_PAGE = 10;
  const [allData, setAllData] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const { data, loading, error } = useQuery(SEE_ALL_CHARGE_REQUEST, {
    fetchPolicy: 'network-only'
  });
  // *** admin auth
  const email = useInput('');
  const adminSecret = useInput('');
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [adminLoginMutation] = useMutation(ADMIN_LOGIN);
  const [localLogInMutation] = useMutation(LOCAL_LOG_IN);

  const [confirmChargeRequestMutation] = useMutation(CONFIRM_CHARGE_REQUEST, {
    refetchQueries: [{ query: SEE_ALL_CHARGE_REQUEST }],
    awaitRefetchQueries: true
  });

  useEffect(() => {
    if (error) {
      setIsAuthenticated(false);
    }
  }, [error]);

  useEffect(() => {
    if (data && data.seeAllChargeRequest) {
      setAllData(data.seeAllChargeRequest.filter(data => !data.isRefund));
      setPrintData(
        data.seeAllChargeRequest
          .filter(data => !data.isRefund)
          .slice(0, ROWS_PER_PAGE)
      );
    }
  }, [data]);

  const displayDate = tz => {
    const dt = new Date(tz);
    return dt.getFullYear() + '/' + (dt.getMonth() + 1) + '/' + dt.getDate();
  };

  const handlePageChange = pageNumber => {
    const start = (pageNumber - 1) * ROWS_PER_PAGE;
    setPrintData(allData.slice(start, start + ROWS_PER_PAGE));
    setActivePage(pageNumber);
  };

  const handleLoginSubmit = async e => {
    e.preventDefault();
    setButtonLoading(true);
    const {
      data: { adminLogin }
    } = await adminLoginMutation({
      variables: { email: email.value, adminSecret: adminSecret.value }
    });

    if (adminLogin !== null) {
      localLogInMutation({ variables: { token: adminLogin } });
      window.location.reload();
    } else {
      toast.error('secret을 다시 확인하세요.');
    }
    setButtonLoading(false);
  };

  const handleConfirmClick = async confirmData => {
    if (
      window.confirm(
        `한 번 승인하면 되돌릴 수 없습니다. 아래 내용을 다시한번 확인해 주세요.\n\n입금자명: ${
          confirmData.depositName
        }\n충전금액: ${confirmData.amount}\n신청일자: ${displayDate(
          confirmData.createdAt
        )}`
      )
    ) {
      setButtonLoading(true);
      try {
        const {
          data: confirmChargeRequest
        } = await confirmChargeRequestMutation({
          variables: { requestId: confirmData.id }
        });
        if (!confirmChargeRequest) {
          toast.error('잠시 후 다시 시도해 주세요');
        }
      } catch (err) {
        console.log(err);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  if (loading) return <Loader />;
  if (!isAuthenticated) {
    return (
      <Wrapper>
        <PollinLogo />
        <Form>
          <form onSubmit={handleLoginSubmit}>
            <Input placeholder={'e-mail'} type='email' {...email} />
            <Input placeholder={'Secret'} type='password' {...adminSecret} />
            <Button
              loading={buttonLoading}
              text={'로그인'}
              disabled={buttonLoading}
            />
          </form>
        </Form>
      </Wrapper>
    );
  }
  return (
    <AdminWrapper>
      <AdminPollinLogo color='grey' width='w' text='Admin' />
      <AdminForm width='w'>
        <div style={{ textAlign: 'end', marginBottom: 15 }}>
          <NavLink to='/admin/refund'>환불 신청 관리</NavLink>
          <NavLink to='/admin'>투표 관리</NavLink>
        </div>
        <Header>
          <span style={{ width: 40 }}>처리상태</span>
          <span style={{ flex: 1 }}>입금자명</span>
          <span style={{ flex: 1 }}>연락처</span>
          <span style={{ flex: 1 }}>충전금액</span>
          <span style={{ width: 100 }}>신청 유저</span>
          <span style={{ width: 70 }}>신청일자</span>
          <span style={{ width: 150 }} />
        </Header>
        {printData && (
          <>
            {printData.map(chargeRequest => (
              <ItemRows key={chargeRequest.id}>
                <span style={{ width: 40, textAlign: 'center' }}>
                  <Dot
                    fill={
                      chargeRequest.status === 'CONFIRM'
                        ? '#0FBE00'
                        : chargeRequest.status === 'PROGRESS'
                        ? '#FBE600'
                        : '#CD4141'
                    }
                  />
                </span>
                <span
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontWeight: 600
                  }}
                >
                  {chargeRequest.depositName}
                </span>
                <span
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontWeight: 600
                  }}
                >
                  {chargeRequest.depositTel}
                </span>
                <span
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontWeight: 600
                  }}
                >
                  {chargeRequest.amount}
                </span>
                <span
                  className='Admin-username-text'
                  style={{ width: 100, flexShrink: 0, textAlign: 'center' }}
                  onClick={() =>
                    history.push(`/profile/${chargeRequest.user.username}`)
                  }
                >
                  {chargeRequest.user.username}
                </span>
                <span style={{ width: 70, flexShrink: 0, textAlign: 'center' }}>
                  {displayDate(chargeRequest.createdAt)}
                </span>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    padding: '0px 10px',
                    width: 150,
                    justifyContent: 'space-between'
                  }}
                >
                  {chargeRequest.status === 'CONFIRM' && (
                    <div
                      style={{
                        flex: 1,
                        textAlign: 'center'
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>승인됨</p>
                      <span style={{ marginRight: 8 }}>
                        {chargeRequest.processedBy.username}
                      </span>
                      <span>{displayDate(chargeRequest.updatedAt)}</span>
                    </div>
                  )}
                  {chargeRequest.status === 'PROGRESS' && (
                    <Button
                      type='button'
                      theme='white'
                      value={chargeRequest}
                      text='승인'
                      onClick={() => handleConfirmClick(chargeRequest)}
                      loading={buttonLoading}
                    />
                  )}
                  {chargeRequest.status === 'CANCEL' && (
                    <div
                      style={{
                        flex: 1,
                        textAlign: 'center'
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>
                        {chargeRequest.adminNote}
                      </p>
                      <span style={{ marginRight: 8 }}>
                        {chargeRequest.processedBy &&
                          chargeRequest.processedBy.username}
                      </span>
                      <span>{displayDate(chargeRequest.updatedAt)}</span>
                    </div>
                  )}
                </div>
              </ItemRows>
            ))}
          </>
        )}
        {printData && printData.length === 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 150
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#999',
                textAlign: 'center'
              }}
            >
              결과가 없습니다.
            </span>
          </div>
        )}
      </AdminForm>
      {allData && allData.length > ROWS_PER_PAGE && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ROWS_PER_PAGE}
          totalItemsCount={allData.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      )}
    </AdminWrapper>
  );
};
export default withRouter(AdminCharging);

const AdminWrapper = styled(Wrapper)`
  .undefined {
    color: black !important;
  }
  li {
    font-size: 16px;
    margin: 0px 10px;
    display: inline-block;
  }
`;

const AdminPollinLogo = styled(PollinLogo)`
  min-width: 700px;
  max-width: 700px;
  word-break: keep-all;
`;

const AdminForm = styled.div`
  min-width: 700px;
  max-width: 700px;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  li {
    display: inline-block;
    margin: 0px 4px;
    color: #3897f0;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  span {
    text-align: center;
    font-weight: 600;
  }
`;

const ItemRows = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  .Admin-title-text,
  .Admin-username-text {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const NavLink = styled(Link)`
  margin: 0px 10px;
  text-decoration: underline;
`;
