import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`
  ${reset};
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
  * { 
    box-sizing:border-box;
  }
  body {
    background-color: #F5F5F5;
    color: #262626;
    font-size:14px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  a {
    color: #3897f0;
    text-decoration:none;
  }
  input:focus{
    outline:none;
  }
`;
