import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';
import Button from '../../Components/Button';
import { Info } from '../../Components/Icons';
import Emoji from '../../Components/Emoji';
import Question from '../../Components/Question';
import Wrapper from '../../Components/Wrapper';
import PollinLogo from '../../Components/PollinLogo';
import Form from '../../Components/Form';
import Textarea from '../../Components/Textarea';
import ImageLoader from '../../Components/ImageLoader';
import Location from '../../Components/Location';
import CreditModelSelect from '../../Components/CreditModelSelect';
import Switch from 'react-switch';
import InfoText from '../../Components/InfoText';

const CreatePresenter = (
  { history, buttonLoading, onSubmit, width, poll, user, mode = 'create' },
  ref
) => (
  <Wrapper>
    {mode === 'create' ? (
      <Helmet title='Create | Pollin' />
    ) : (
      <Helmet title='Edit | Pollin' />
    )}
    <form style={{ paddingBottom: 50 }} onSubmit={onSubmit} ref={ref}>
      <fieldset disabled={!!poll.id}>
        <PollinLogo color='grey' width='w' />
        <Form width='w'>
          <h1>투표 주제</h1>
          <Textarea
            className='poll-title'
            name='title'
            style={{ borderBottomWidth: 2 }}
            placeholder='투표 주제 또는 행사명을 입력해 주세요'
            required={true}
            value={poll.title}
            onChange={poll.onChange}
          />
          <Textarea
            className='poll-description'
            name='description'
            placeholder='추가 설명이 필요할 경우 입력해 주세요'
            size={16}
            required={false}
            value={poll.description}
            onChange={poll.onChange}
          />
        </Form>
        <Form width='w'>
          <h2>타이틀 이미지</h2>
          <ImageLoader
            image={poll.titleImage}
            onChange={poll.onImageDrop}
            width={width}
          />
        </Form>

        {mode === 'create' && <Location poll={poll} userIps={user.ips} />}
        {mode === 'edit' && poll.creditModel && (
          <Location poll={poll} userIps={user.ips} />
        )}

        <Form width='w'>
          <div
            style={{ display: 'flex', alignItems: 'center ', marginBottom: 8 }}
          >
            <ControlItemTitle>QnA</ControlItemTitle>
            <Switch
              onColor='#1d78cc'
              onChange={poll.toggleUsingMessage}
              checked={poll.usingMessage}
            />
          </div>
          <InfoText text='투표 참여자의 질문 및 의견을 받습니다' />
        </Form>

        <Form width='w'>
          <div
            style={{ display: 'flex', alignItems: 'center ', marginBottom: 8 }}
          >
            <ControlItemTitle>추첨</ControlItemTitle>
            <Switch
              onColor='#1d78cc'
              onChange={poll.toggleUsingLot}
              checked={poll.usingLot}
            />
          </div>
          <InfoText text='추첨 및 응모 번호 발급 기능을 사용합니다' />
        </Form>

        {poll.questions.map((question, index) => (
          <Question
            key={index}
            question={question}
            index={index}
            removeQuestion={poll.removeQuestion}
            disabled={!!poll.id}
          />
        ))}

        {!poll.id && (
          <AddQuestion onClick={poll.addQuestion} width='w'>
            <QuestionNumber>질문 {poll.questions.length + 1}</QuestionNumber>
          </AddQuestion>
        )}

        {poll.usingLocation && mode === 'create' && (
          <>
            <CreditModelForm width='w'>
              <h2>참여 인원 선택</h2>
              <div className='Select-wrap'>
                <CreditModelSelect
                  onClick={() => poll.handleCreditModelSelect(1)}
                  selected={poll.creditModel === 1}
                >
                  <p>50 명 이내</p>
                  <p>30,000 크레딧 차감</p>
                </CreditModelSelect>
                <CreditModelSelect
                  onClick={() => poll.handleCreditModelSelect(2)}
                  selected={poll.creditModel === 2}
                >
                  <p>50 ~ 150 명</p>
                  <p>60,000 크레딧 차감</p>
                </CreditModelSelect>
                <CreditModelSelect
                  onClick={() => poll.handleCreditModelSelect(3)}
                  selected={poll.creditModel === 3}
                >
                  <p>150 ~ 400 명</p>
                  <p>90,000 크레딧 차감</p>
                </CreditModelSelect>
                <CreditModelSelect
                  onClick={() => poll.handleCreditModelSelect(4)}
                  selected={poll.creditModel === 4}
                >
                  <p>400 명 이상</p>
                  <p>120,000 크레딧 차감</p>
                </CreditModelSelect>
              </div>
              <div className='Info-wrap'>
                <Info size='14' fill='#999' />
                <span> 인원 초과분은 투표 종료 후 재정산됩니다</span>
              </div>
              <div className='Credit-calc'>
                <div>
                  <p>보유 크레딧</p>
                  <p>{user.credit || 0}</p>
                </div>
                <div>
                  <p>차감 크레딧</p>
                  <p>{poll.creditModel * 30000}</p>
                </div>
                <div>
                  <p>잔여 크레딧</p>
                  <p>{user.credit - poll.creditModel * 30000} </p>
                </div>
              </div>
              {!poll.creditModel && <p>예상 참여 인원을 선택해 주세요!</p>}
              {user.credit - poll.creditModel * 30000 < 0 && (
                <p>보유 크레딧이 부족합니다</p>
              )}
            </CreditModelForm>
          </>
        )}
      </fieldset>
      <Form width='w'>
        {mode === 'create' ? (
          !!poll.id ? (
            <>
              <Announce>
                <span style={{ fontSize: 18, fontWeight: 800 }}>
                  투표가 개설됐습니다!
                </span>
                <Emoji symbol=' 🎉' size='24px' />
                <div style={{ color: '#999', fontWeight: 600 }}>
                  관리 페이지에서 투표를 공유하고, 결과를 확인해 보세요
                </div>
              </Announce>
              <Button
                text={'관리 페이지로 이동하기'}
                type='button'
                onClick={() => {
                  history.push(`/manage/${poll.id}`);
                }}
              />
            </>
          ) : (
            <Button
              text={'테스트 모드로 시작하기'}
              loading={buttonLoading}
              disabled={buttonLoading}
            />
          )
        ) : (
          <Button
            text={'수정 완료'}
            loading={buttonLoading}
            disabled={buttonLoading}
          />
        )}
      </Form>
    </form>
  </Wrapper>
);

export default observer(forwardRef(CreatePresenter));

const Announce = styled.div`
  margin-right: -30px;
  margin-bottom: 20px;
`;

const AddQuestion = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: white;
  width: ${props => (props.width === 'n' ? '350px' : '500px')};
  min-width: 320px;
  max-width: 95vw;
  padding: 30px;
  height: 80px;
  border-bottom: 0;
  margin-bottom: 40px;
  background: linear-gradient(white, white, white, #f5f5f5);
  :hover {
    cursor: pointer;
    border: 1px solid #3897f0;
  }
`;

const QuestionNumber = styled.span`
  flex: 1;
  font-size: 18px;
  font-weight: 800;
  align-self: flex-end;
`;

const CreditModelForm = styled(Form)`
  color: #262626;
  h2 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  > p {
    text-align: end;
    :last-child {
      font-size: 16px;
      color: red;
    }
  }
  .Info-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #999;
  }
  .Credit-calc {
    > div:nth-child(2) {
      border-left: 1px solid #999;
      border-right: 1px solid #999;
    }
    display: flex;
    align-items: center;
    text-align: center;
    margin: 30px 0px;
    > div {
      flex: 1;
    }
    p:last-child {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const ControlItemTitle = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 800;
`;
