import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Emoji from './Emoji';
import Form from './Form';

const Done = ({ isTest = false }) => {
  return (
    <DoneFrom width='w'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: 220,
          color: '#262626'
        }}
      >
        <p
          style={{
            fontSize: 22,
            fontWeight: 800,
            paddingBottom: 20
          }}
        >
          투표 완료!
        </p>
        <p
          style={{
            fontWeight: 600,
            fontSize: 18
          }}
        >
          참여해 주셔서 감사합니다
          <Emoji symbol=' 🙂' size='20px' />
        </p>
        {isTest && (
          <p style={{ color: 'red', marginTop: 20 }}>
            테스트모드에서는 페이지 새로고침으로 여러번 투표에 참여할 수
            있습니다
          </p>
        )}
      </div>
    </DoneFrom>
  );
};
export default withRouter(Done);

const DoneFrom = styled(Form)`
  min-height: 300px;
`;
