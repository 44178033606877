import React, { useEffect, useState } from 'react';
import Form from '../Components/Form';
import PollinLogo from '../Components/PollinLogo';
import styled from 'styled-components';
import Wrapper from '../Components/Wrapper';
import { useQuery } from 'react-apollo-hooks';
import { SEE_MESSAGES } from '../queries';
import Button from '../Components/Button';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/ko';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import InfoText from '../Components/InfoText';
import NavButton from '../Components/NavButton';

const formatter = buildFormatter(frenchStrings);

export default ({
  history,
  match: {
    params: { id }
  }
}) => {
  const [displayData, setDisplayData] = useState([]);
  const { data, loading, refetch, fetchMore } = useQuery(SEE_MESSAGES, {
    variables: { pollId: id, skip: 0 },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (data && data.seeMessages) {
      const len = data.seeMessages.length;
      if (len >= 30) {
        setDisplayData(data.seeMessages.slice(len - 30));
      } else {
        setDisplayData(data.seeMessages);
      }
    }
  }, [data]);

  return (
    <QnaWrapper>
      <PollinLogo color='grey' width='w' />
      <QnaForm width='w'>
        <NavBox>
          <NavButton
            text='투표 관리'
            arrow='left'
            onClick={() => history.push(`/manage/${id}`)}
          />
        </NavBox>
        <div className='header'>
          <h1>QnA</h1>
          <Button
            className='refesh-btn'
            text='새로고침'
            onClick={() => refetch()}
            loading={loading}
            disabled={loading}
          />
        </div>
        {displayData.map(message => {
          return (
            <div className='message' key={message.id}>
              <div className='name-ago'>
                <span>{message.name || '익명'}</span>
                <TimeAgo date={message.createdAt} formatter={formatter} />
              </div>
              <p className='content'>{message.message}</p>
            </div>
          );
        })}
        {displayData.length === 0 && (
          <div className='message'>
            <InfoText text='아직 메세지가 없습니다' />
          </div>
        )}
        {data && data.seeMessages.length < data.messageLength && (
          <Button
            text='더보기'
            theme='white'
            loading={loading}
            disabled={loading}
            onClick={() => {
              fetchMore({
                variables: {
                  skip: data.seeMessages.length
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    seeMessages: [
                      ...prev.seeMessages,
                      ...fetchMoreResult.seeMessages
                    ]
                  });
                }
              });
            }}
          />
        )}
      </QnaForm>
    </QnaWrapper>
  );
};

const QnaWrapper = styled(Wrapper)`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .refesh-btn {
      width: 100px;
    }
  }
  .message {
    align-items: center;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 20px;
    margin: 8px 0px;
    .name-ago {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-weight: 600;
      color: #999;
    }
    .content {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const QnaForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 300px;
`;

const NavBox = styled.div`
  display: flex;
  margin-top: -15px;
  margin-bottom: 20px;
  button {
    :first-child {
      margin-right: 4px;
    }
    :last-child {
      margin-left: 4px;
    }
  }
`;
