import React, { useState } from 'react';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload-fixed';
import InfoText from './InfoText';

const ImageLoader = ({ image, onChange, width }) => {
  const [imageAccepted, setImageAccepted] = useState(false);
  const [prevImagesLength, setPrevImagesLength] = useState(0);

  return image ? (
    <Container>
      <img
        src={typeof image === 'object' ? URL.createObjectURL(image) : image}
        alt='타이틀 이미지'
        width={`${width - 60}px`}
      />
      <ImageUploader
        fileContainerStyle={{
          color: '#999',
          boxShadow: 'none',
          padding: 0
        }}
        buttonStyles={{ background: '#262626' }}
        withIcon={false}
        withLabel={false}
        buttonText='이미지 변경'
        onChange={images => {
          if (images.length > prevImagesLength) {
            setImageAccepted(true);
          } else {
            setImageAccepted(false);
          }
          setPrevImagesLength(images.length);
          onChange(images);
        }}
        label='.jpg .jpeg .png 이미지 파일을 선택해 주세요 | 최대 크기 5MB'
        labelStyles={{ textAlign: 'center' }}
        imgExtension={['.jpg', 'jpeg', '.png']}
        fileSizeError='파일 용량이 너무 큽니다'
        fileTypeError='지원하지 않는 파일 형식입니다'
        errorStyle={
          imageAccepted
            ? { color: 'rgba(0,0,0,0)' }
            : { color: 'rgba(255,0,0,1)' }
        }
        maxFileSize={5242880}
        singleImage={true}
      />
    </Container>
  ) : (
    <Container>
      <InfoText
        text={'투표를 잘 표현할 수 있는 이미지를 한 장 게시해 보세요'}
      />
      <ImageUploader
        fileContainerStyle={{
          borderColor: '#999',
          borderWidth: 1,
          borderStyle: 'solid',
          boxShadow: 'none',
          color: '#999'
        }}
        buttonStyles={{ background: '#262626' }}
        withIcon={false}
        buttonText='이미지 선택'
        onChange={images => {
          if (images.length > prevImagesLength) {
            setImageAccepted(true);
          } else {
            setImageAccepted(false);
          }
          setPrevImagesLength(images.length);
          onChange(images);
        }}
        label='.jpg .jpeg .png 이미지 파일을 선택해 주세요 | 최대 크기 5MB'
        labelStyles={{ textAlign: 'center' }}
        imgExtension={['.jpg', 'jpeg', '.png']}
        fileSizeError='파일 용량이 너무 큽니다'
        fileTypeError='지원하지 않는 파일 형식입니다'
        errorStyle={
          imageAccepted
            ? { color: 'rgba(0,0,0,0)' }
            : { color: 'rgba(255,0,0,1)' }
        }
        maxFileSize={5242880}
        singleImage={true}
      />
    </Container>
  );
};
export default ImageLoader;

const Container = styled.div`
  img {
    width: 100%;
  }
`;
