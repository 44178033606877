import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../Routes/Home';
import Create from '../Routes/Create';
import Result from '../Routes/Result';
import Manage from '../Routes/Manage';
import Auth from '../Routes/Auth';
import Edit from '../Routes/Edit';
import Admin from '../Routes/Admin';
import Profile from '../Routes/Profile';
import { Provider, inject } from 'mobx-react';
import Terms from '../Routes/Terms';
import ReturnPolicy from '../Routes/ReturnPolicy';
import Charging from '../Routes/Charging';
import AdminCharging from '../Routes/AdminCharging';
import AdminRefund from '../Routes/AdminRefund';
import Vote from '../Routes/Vote';
import { Loader } from 'semantic-ui-react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Qna from '../Routes/Qna';
import Lot from '../Routes/Lot';

const CHECK_LOCAL_LOGIN = gql`
  {
    isLoggedIn @client
  }
`;

const AppRouter = ({ user, poll }) => {
  const { data, loading } = useQuery(CHECK_LOCAL_LOGIN);

  if (loading) return <Loader />;
  return (
    <Switch>
      <Route exact path='/' component={data.isLoggedIn ? Home : Auth} />
      <Route path='/auth' component={data.isLoggedIn ? Home : Auth} />

      {/* 투표 참여 */}
      <Route exact path='/vote/:id' component={Vote} />

      {/* 투표 생성 */}
      {data.isLoggedIn && (
        <Route
          path='/create'
          render={() => {
            poll.reset();
            return (
              <Provider poll={poll} user={user}>
                <Create />
              </Provider>
            );
          }}
        />
      )}

      {/* 투표 관리 */}
      {data.isLoggedIn && <Route exact path='/manage/:id' component={Manage} />}
      {data.isLoggedIn && (
        <Route exact path='/manage/:id/result' component={Result} />
      )}
      {data.isLoggedIn && (
        <Route exact path='/manage/:id/qna' component={Qna} />
      )}
      {data.isLoggedIn && (
        <Route exact path='/manage/:id/lot' component={Lot} />
      )}
      {data.isLoggedIn && (
        <Route
          exact
          path='/manage/:id/edit'
          render={() => {
            return (
              <Provider poll={poll} user={user}>
                <Edit />
              </Provider>
            );
          }}
        />
      )}

      {/* 유저 정보 */}
      {data.isLoggedIn && (
        <Route
          exact
          path='/profile/:username'
          render={() => {
            return (
              <Provider user={user}>
                <Profile />
              </Provider>
            );
          }}
        />
      )}
      {data.isLoggedIn && (
        <Route
          exact
          path='/charging/:username'
          render={() => {
            return (
              <Provider user={user}>
                <Charging />
              </Provider>
            );
          }}
        />
      )}
      {data.isLoggedIn && (
        <Route
          exact
          path='/charging'
          render={() => {
            return (
              <Provider user={user}>
                <Charging />
              </Provider>
            );
          }}
        />
      )}

      {/* 약관 및 환불정책 */}
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/returnpolicy' component={ReturnPolicy} />

      {/* admin 관련 */}
      <Route exact path='/admin' component={Admin} />
      <Route exact path='/admin/charging' component={AdminCharging} />
      <Route exact path='/admin/refund' component={AdminRefund} />
      <Redirect path='*' to='/' />
    </Switch>
  );
};

export default inject('poll', 'user')(AppRouter);
