import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Textarea from './Textarea';

const Item = ({ item, index, onItemChange, disabled }) => {
  return (
    <Container>
      <Textarea
        name={index}
        placeholder={`항목 ${index + 1}`}
        size={18}
        required={true}
        value={item.title}
        onChange={onItemChange}
        disabled={disabled}
      />
    </Container>
  );
};

export default observer(Item);

const Container = styled.div`
  flex: auto;
  padding: 0;
`;
