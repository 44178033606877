import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import { Add, Remove, Xmark } from './Icons';
import IconButton from './IconButton';
import Item from './Item';
import FatText from './FatText';
import { Radio, Checkbox } from '@material-ui/core';
import Textarea from './Textarea';
import InfoText from './InfoText';
import Form from './Form';

const useStyles = makeStyles({
  root: {
    paddingTop: '10px',
    paddingBottom: '10px',
    flexShrink: 0
  }
});

const Question = ({
  question,
  index,
  removeQuestion,
  mode = 'input',
  disabled = false,
  selectedValue,
  handleSelect
}) => {
  const classes = useStyles();
  return (
    <QuestionForm width='w'>
      <QuestionHeader>
        <QuestionNumber>질문 {index + 1}</QuestionNumber>
        {mode === 'input' && (
          <IconButton
            value={question.index}
            onClick={removeQuestion}
            disabled={disabled}
          >
            <Xmark />
          </IconButton>
        )}
      </QuestionHeader>
      <Textarea
        name='title'
        style={{ borderBottomWidth: 2 }}
        required={true}
        value={question.title}
        onChange={question.handleChange}
        disabled={disabled}
      />
      <QuestionTypeContainer>
        {mode === 'input' && (
          <FormControl variant='outlined' disabled={disabled}>
            <Select
              name='type'
              classes={{ root: classes.root }}
              value={question.type}
              onChange={question.handleChange}
              displayEmpty
            >
              <MenuItem value='ONLY'>
                <span style={{ fontSize: 14, fontWeight: 600 }}>단일선택</span>
              </MenuItem>
              <MenuItem value='MULTI'>
                <span style={{ fontSize: 14, fontWeight: 600 }}>다중선택</span>
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <div style={{ marginLeft: 4, flexShrink: 1 }}>
          {question.type === 'ONLY' ? (
            mode === 'input' ? (
              <InfoText text='하나의 항목에만 투표할 수 있습니다' />
            ) : (
              <InfoText text='단일 선택' />
            )
          ) : mode === 'input' ? (
            <InfoText text='여러 개 항목에 투표할 수 있습니다' />
          ) : (
            <InfoText text='다중 선택' />
          )}
        </div>
      </QuestionTypeContainer>
      <ItemContainer>
        {question.items.map((item, index) => (
          <ItemRows key={item.index || index}>
            <Item
              item={item}
              index={index}
              onItemChange={question.handleItemChange}
              disabled={disabled}
            />
            {mode === 'input' && (
              <IconButton onClick={question.removeItem} value={item.index}>
                <Remove />
              </IconButton>
            )}
            {mode === 'print' && (
              <CountContainer>
                <FatText text={item.voteCount.toString()} size='18px' />
              </CountContainer>
            )}
            {mode === 'vote' &&
              (question.type === 'ONLY' ? (
                <Radio
                  checked={selectedValue.includes(item.id)}
                  onChange={e => handleSelect(e, 'ONLY')}
                  value={item.id}
                  name={question.id}
                />
              ) : (
                <Checkbox
                  checked={selectedValue.includes(item.id)}
                  onChange={e => handleSelect(e, 'MULTI')}
                  value={item.id}
                  name={question.id}
                />
              ))}
          </ItemRows>
        ))}
        {mode === 'input' && (
          <IconButton
            onClick={question.addItem}
            color='#262626'
            disabled={disabled}
          >
            <Add />
          </IconButton>
        )}
      </ItemContainer>
    </QuestionForm>
  );
};
export default observer(Question);

const QuestionForm = styled(Form)`
  position: relative;
  &:last-child {
    padding: 30px;
    margin-bottom: 50px;
  }
  fieldset {
    z-index: 3;
  }
`;
const QuestionHeader = styled.div`
  display: flex;
  button {
    position: absolute;
    margin-top: -20px;
    right: 10px;
  }
`;

const QuestionNumber = styled.span`
  flex: 1;
  font-size: 18px;
  font-weight: 800;
  align-self: flex-end;
`;

const QuestionTypeContainer = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 15px;
  }
`;

const ItemContainer = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  button {
    align-self: center;
  }
`;

const ItemRows = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CountContainer = styled.span`
  height: 42px;
  line-height: 42px;
  width: 42px;
  text-align: center;
`;
