import React from 'react';
import { withRouter } from 'react-router-dom';
import Wrapper from '../Components/Wrapper';
import PollinLogo from '../Components/PollinLogo';
import Form from '../Components/Form';
import styled from 'styled-components';
import Button from '../Components/Button';

export default withRouter(({ history }) => {
  return (
    <Wrapper>
      <PollinLogo width='w' />
      <Form width='w'>
        {/* prettier-ignore */}
        <ReturnPolicy>
          <h1>환불정책</h1>
            폴린(Pollin)의 환불 및 취소 안내입니다.
            <br/><br/>
            폴린(Pollin)에서는 다음과 같은 규정에 따라 언제든지 환불 신청을 하실 수 있습니다.
            <h3>환불요청은 아래와 같은 방법으로 접수가 가능합니다.</h3>
            -	고객센터 직접 문의 (help@lpin.io)<br/>
            * 환불은 신청 접수 후 14일 이내에 처리해 드립니다.<br/>
            <h3>[환불 절차]</h3>
            고객센터 환불접수 -> 환불가능 여부 확인 -> 결제 취소 또는 환불금 입금<br/>
            <h3>전액 또는 부분 환불이 가능한 경우는 아래와 같습니다.</h3>
            -	크레딧 포인트 구매 후, 사용 이력이 없고 구매(충전)일로 부터 7일이 지나지 않은 경우 전액 환불 가능<br/>
            -	서비스 마지막 사용 일자 기준 1년 경과 시 잔여 크레딧 포인트 환불 신청 가능<br/>

        </ReturnPolicy>
        <Button onClick={() => history.push('/')} text='확인' />
      </Form>
    </Wrapper>
  );
});

const ReturnPolicy = styled.div`
  margin-bottom: 30px;
  h1 {
    font-size: 24px;
    font-weight: 800;
    margin: 30px 0px;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0px 10px 0px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0px 10px 0px;
  }
`;
