import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default () => (
  <Footer>
    <Links>
      <a href='mailto:help@lpin.io'>서비스 문의 - help@lpin.io</a>
      <Link to='/terms'>이용약관</Link>
      <Link to='/returnpolicy'>환불정책</Link>
    </Links>
    <br />
    <Info>
      <span>상호 : 주식회사 엘핀 ｜ 대표이사 : 박영경</span>
      <span>소재지 : 서울특별시 마포구 백범31길 21 서울핀테크랩 222호</span>
      <span>
        사업자등록번호 : 317-81-47616 ｜ 통신판매번호 : 서울마포0901호
      </span>
      <span>전화 : 02-2059-3604</span>
      <span> (상담시간 - 평일 10:00~18:00, 점심시간 - 12:30~13:30)</span>
      <span>COPYRIGHT (주) L Fin. All Rights reserved</span>
    </Info>
  </Footer>
);

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin: 20px 0px;
  color: #999;
`;

const Links = styled.div`
  display: flex;
  font-weight: 600;
  a {
    margin: 0 10px;
    color: #262626;
    font-weight: 800;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
