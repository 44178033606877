import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Add, Check } from './Icons';
import IconButton from './IconButton';
import Form from './Form';
import InfoText from './InfoText';
import Switch from 'react-switch';
import Input from './Input';
import Button from './Button';
import { useMutation } from 'react-apollo-hooks';
import { GET_CURRENT_IP } from '../queries';

const Location = ({ poll, userIps }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [ipInput, setIpInput] = useState('');
  const [currentIp, setCurrentIp] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [getCurrentIpMutation] = useMutation(GET_CURRENT_IP);

  const isValidIp = ip => {
    if (ip.split('.').length !== 4) {
      return false;
    }
    ip.split('.').forEach(block => {
      if (block < 0 || block > 255) return false;
    });
    return true;
  };

  const handleIpInputDone = () => {
    if (ipInput === '') {
    } else if (!isValidIp(ipInput)) {
      alert('유효한 네트워크 주소를 입력하세요');
    } else {
      poll.addIp(ipInput);
    }
    setShowInput(false);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.name === 'ip-input') {
        handleIpInputDone();
      } else {
        handleSave();
      }
    }
  };

  const handleSave = async () => {
    if (!poll.wifi.ssid) {
      return alert('네트워크 이름을 설정해 주세요');
    }

    try {
      setButtonLoading(true);

      const {
        data: { getCurrentIp }
      } = await getCurrentIpMutation();
      setCurrentIp(getCurrentIp);

      poll.addIp(getCurrentIp);
      setSaveDisabled(true);
    } catch (err) {
      console.log(err);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleWifiChange = e => {
    setSaveDisabled(false);
    poll.handleWifiChange(e);
  };

  const handleIpRemove = e => {
    if (window.confirm('네트워크 주소를 삭제하시겠습니까?')) {
      poll.removeIp(e);
    }
  };

  return (
    <Form width='w'>
      <NetwrokHeader>
        <ControlItemTitle>위치 제한</ControlItemTitle>
        <Switch
          onColor='#1d78cc'
          onChange={poll.toggleUsingLocation}
          checked={poll.usingLocation}
        />
      </NetwrokHeader>

      {!poll.usingLocation ? (
        <InfoText text='투표 참여가 허용되는 네트워크를 지정합니다' />
      ) : (
        <div>
          <h3>Wi-Fi</h3>
          <InfoText text='투표 참여 페이지에 아래의 Wi-Fi 접속 정보가 안내됩니다' />
          <Wifi>
            <div>
              <label>
                네트워크 이름
                <LineInput
                  name='ssid'
                  value={poll.wifi.ssid}
                  onChange={handleWifiChange}
                  onKeyPress={handleEnterPress}
                />
              </label>
              <label>
                비밀번호
                <LineInput
                  name='password'
                  value={poll.wifi.password}
                  onChange={handleWifiChange}
                  onKeyPress={handleEnterPress}
                  required={false}
                />
              </label>
            </div>
            <div className='btn-wrap'>
              {!saveDisabled && (
                <span>해당 Wi-Fi에 연결한 상태로 저장하세요</span>
              )}
              <Button
                text='저장'
                type='button'
                onClick={handleSave}
                loading={buttonLoading}
                disabled={poll.id || saveDisabled}
              />
            </div>
          </Wifi>

          <h3>네트워크 주소</h3>
          <InfoText text='여러 개의 네트워크를 사용중이거나, 다른 장소의 네트워크를 사용하려면 직접 주소를 추가해 주세요' />
          <div>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0px' }}
            >
              {poll.ips.map(ip => (
                <IpItem
                  key={ip.index}
                  type='button'
                  name={ip.index}
                  value={ip.address}
                  onClick={handleIpRemove}
                  disabled={ip.address === currentIp}
                >
                  {ip.address}
                </IpItem>
              ))}
              {!showInput && (
                <IconButton
                  onClick={() => {
                    setShowInput(true);
                    setIpInput('');
                  }}
                  disabled={poll.id}
                >
                  <Add />
                </IconButton>
              )}
            </div>
            <div>
              {showInput && (
                <ItemRows>
                  <IpInput
                    name='ip-input'
                    placeholder={`xxx.xxx.xxx.xxx`}
                    value={ipInput}
                    onChange={e => setIpInput(e.target.value)}
                    onKeyPress={handleEnterPress}
                  />
                  <IconButton
                    onClick={handleIpInputDone}
                    type='button'
                    disabled={poll.id}
                  >
                    <Check />
                  </IconButton>
                </ItemRows>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
export default observer(Location);

const NetwrokHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-left: 0px;
  }
`;

const ItemRows = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ControlItemTitle = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 800;
`;

const IpItem = styled.button`
  padding: 5px;
  margin: 5px;
  height: 34px;
  border: 1px solid #262626;
  border-radius: 4px;
  color: #262626;
  background: white;
  outline: 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    ${props => !props.disabled && 'background: #e6e6e6;'}
  }
`;

const LineInput = styled(Input)`
  margin: 10px 0px;
  border: 0;
  background-color: white;
  border-radius: 0;
  border-bottom: 2px solid #999;
  font-size: 18px;
  :: placeholder {
    font-size: 18px;
  }
`;

const Wifi = styled.div`
  margin-top: 4px;
  padding: 15px;

  label {
    color: #999;
    font-weight: 500;
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    text-align: end;
    word-break: keep-all;
    button {
      width: 80px;
      margin-top: 0px;
    }
    span {
      color: red;
      font-size: 12px;
      margin-right: 8px;
    }
  }
`;

const IpInput = styled(Input)`
  margin: 10px 0px;
  border: 0;
  background-color: white;
  border-radius: 0;
  border-bottom: 2px solid #999;
  font-size: 18px;
  :: placeholder {
    font-size: 18px;
  }
`;
