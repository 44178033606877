import React, { useEffect, useState } from 'react';
import Form from '../Components/Form';
import Wrapper from '../Components/Wrapper';
import PollinLogo from '../Components/PollinLogo';
import Button from '../Components/Button';
import InfoText from '../Components/InfoText';
import { useQuery, useMutation, useSubscription } from 'react-apollo-hooks';
import { SEE_LOT_INFO, CAST_LOT, LOT_SUBSCRIPTION } from '../queries';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import NavButton from '../Components/NavButton';

export default ({
  history,
  match: {
    params: { id }
  }
}) => {
  const [winNumber, setWinNumber] = useState(null);
  const [winHistory, setWinHistory] = useState([]);
  const [entryCount, setEntryCount] = useState(0);
  const [isOverCast, setIsOverCast] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { data, loading } = useQuery(SEE_LOT_INFO, {
    variables: { pollId: id }
  });
  const [castLotMutation] = useMutation(CAST_LOT, {
    variables: { pollId: id }
  });
  const { data: lotSubscriptionData } = useSubscription(LOT_SUBSCRIPTION, {
    variables: { pollId: id }
  });

  useEffect(() => {
    if (lotSubscriptionData) {
      setEntryCount(lotSubscriptionData.lotSubscription);
    }
  }, [lotSubscriptionData, setEntryCount]);

  useEffect(() => {
    if (data) {
      setWinHistory(data.seeLotInfo.lotWinHistory);
      setEntryCount(data.seeLotInfo.lotEntryCount);
    }
  }, [data, setWinHistory, setEntryCount]);

  const handleCastlot = async () => {
    if (winNumber) {
      setWinHistory(p => [winNumber, ...p]);
      setWinNumber(null);
    }
    try {
      setButtonLoading(true);
      const {
        data: { castLot }
      } = await castLotMutation();
      if (castLot) {
        setWinNumber(castLot);
      } else {
        setIsOverCast(true);
        toast.error('응모 인원을 넘겨서 추첨할 수 없습니다');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Wrapper>
      <PollinLogo color='grey' width='w' />
      <LotForm width='w'>
        <NavBox>
          <NavButton
            text='투표 관리'
            arrow='left'
            onClick={() => history.push(`/manage/${id}`)}
          />
        </NavBox>
        <h1>추첨</h1>
        <div className='entry'>
          <span className='entry-count'>{entryCount}</span>
          <InfoText text='&nbsp;&nbsp;명 응모 중' size='16px' />
        </div>
        <div className='number-history-wrap'>
          <div className='win-number-wrap'>
            <p>{winNumber}</p>
          </div>
          <div className='win-history-wrap'>
            {winHistory.map((number, i) => (
              <span key={i}>{number}</span>
            ))}
          </div>
        </div>

        <Button
          text={isOverCast ? '추첨 완료' : '추첨하기'}
          theme={isOverCast ? 'white' : 'black'}
          loading={buttonLoading | loading}
          disabled={buttonLoading | isOverCast}
          onClick={handleCastlot}
        />
      </LotForm>
    </Wrapper>
  );
};

const LotForm = styled(Form)`
  .entry {
    text-align: end;
  }
  .entry-count {
    font-size: 20px;
    font-weight: 600;
  }
  .number-history-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    p {
      text-align: center;
      line-height: 200px;
      font-size: 100px;
      font-weight: 600;
    }
    .win-number-wrap {
      min-width: 200px;
      min-height: 200px;
      border: 2px dashed #999;
      border-radius: 8px;
      margin-bottom: 20px;
    }
    .win-history-wrap {
      display: flex;
      flex-wrap: wrap;
      min-height: 50px;
      color: #999;
      font-size: 30px;
      span {
        margin: 10px 15px;
        font-weight: 600;
      }
    }
  }
`;

const NavBox = styled.div`
  display: flex;
  margin-top: -15px;
  margin-bottom: 20px;
  button {
    :first-child {
      margin-right: 4px;
    }
    :last-child {
      margin-left: 4px;
    }
  }
`;
