import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Switch from 'react-switch';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import FatText from '../../Components/FatText';
import IconButton from '../../Components/IconButton';
import { Download, Copy, Wifi, Key } from '../../Components/Icons';
import Input from '../../Components/Input';
import PollinLogo from '../../Components/PollinLogo';
import Wrapper from '../../Components/Wrapper';
import Form from '../../Components/Form';
import InfoText from '../../Components/InfoText';
import NavButton from '../../Components/NavButton';

const ManagePresenter = (
  {
    history,
    data,
    isOpen,
    isTest,
    handleFinishTest,
    handleToggleOpen,
    usingLocation,
    handleToggleUsingLocation,
    usingMessage,
    usingLot,
    downloadQR,
    buttonLoading,
    width,
    id,
    shareLink
  },
  ref
) => (
  <ManageWrapper>
    <Helmet title='Result | Pollin' />
    <div ref={ref}>
      <PollinLogo color='grey' width='w' />
      <Form width='w'>
        <NavBox>
          <NavButton
            text='홈'
            arrow='left'
            onClick={() => {
              history.push(`/`);
              window.fbq('track', 'Search');
            }}
          />
          <div style={{ display: 'flex' }}>
            {isTest && (
              <NavButton
                text='투표 수정'
                onClick={() => history.push(`/manage/${id}/edit`)}
              />
            )}

            <NavButton
              text='투표 결과'
              arrow='right'
              onClick={() => history.push(`/manage/${id}/result`)}
            />
          </div>
        </NavBox>
        <TitleText>{data.title} </TitleText>
        <DescriptionText>{data.description} </DescriptionText>
        {data.titleImage && (
          <div style={{ textAlign: 'center' }}>
            <img
              src={data.titleImage}
              alt='타이틀 이미지'
              width={`${width - 60}px`}
            />
          </div>
        )}
      </Form>
      <Form width='w'>
        {isTest && (
          <div className='test control-wrap top-item'>
            <div className='title control-switch'>
              <ControlItemTitle>테스트 모드 사용 중</ControlItemTitle>
              <NavButtonRed
                text='테스트 종료'
                onClick={handleFinishTest}
                loading={buttonLoading}
              />
            </div>
            <InfoText text='실제 투표 진행 전, 테스트를 종료해 주세요' />
          </div>
        )}
        <ToggleControlRows className={!isTest && 'top-item'}>
          <ControlItemTitle>투표 활성</ControlItemTitle>
          <Switch
            onColor='#1d78cc'
            onChange={handleToggleOpen}
            checked={isOpen}
          />
        </ToggleControlRows>
        {data.creditModel && (
          <div className='Location-wrap control-wrap'>
            <div className='Location-title control-switch'>
              <ControlItemTitle>위치 제한</ControlItemTitle>
              <Switch
                onColor='#1d78cc'
                onChange={handleToggleUsingLocation}
                checked={usingLocation}
              />
            </div>
            <InfoText text='아래 네트워크에서만 투표가 허용됩니다' />
            {data.wifi && (
              <>
                <h3>Wi-Fi</h3>
                <WifiInfo>
                  <ul>
                    <li>
                      <Wifi /> <span>{data.wifi.ssid}</span>
                    </li>
                    <li>
                      <Key />
                      <span>{data.wifi.password || '공개 네트워크'}</span>
                    </li>
                  </ul>
                </WifiInfo>
              </>
            )}
            <h3>네트워크 주소</h3>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0px' }}
            >
              {data.ips.map((ip, index) => (
                <IpItem key={index}>{ip.address}</IpItem>
              ))}
            </div>
          </div>
        )}
        {usingMessage && (
          <div className='Qna-wrap control-wrap'>
            <div className='Qna-title control-switch'>
              <ControlItemTitle>QnA</ControlItemTitle>
              <NavButton
                text='질문 확인'
                arrow='right'
                onClick={() => history.push(`/manage/${id}/qna`)}
              />
            </div>
          </div>
        )}

        {usingLot && (
          <div className='Qna-wrap control-wrap'>
            <div className='Qna-title control-switch'>
              <ControlItemTitle>추첨</ControlItemTitle>
              <NavButton
                text='추첨하기'
                arrow='right'
                onClick={() => history.push(`/manage/${id}/lot`)}
              />
            </div>
          </div>
        )}

        <ShareContainer>
          <ControlItemTitle>투표 공유</ControlItemTitle>
          <InfoText text='QR코드와 공유 링크를 이용해 투표를 공유해 보세요' />
          <QRContainer width={width}>
            <QRCode
              id='qrcode'
              value={`${document.location.href.split('#')[0]}#/vote/${id}`}
              size={width - 4 || 496}
              level='M'
              includeMargin={true}
            />
            <IconButton onClick={downloadQR}>
              <Download />
              <FatText text='QR 다운로드' />
            </IconButton>
          </QRContainer>
          <LinkContainer>
            <Input
              size='14px'
              value={shareLink.value}
              onChange={() => {}}
              disabled={true}
            />
            <CopyToClipboard
              text={shareLink.value}
              onCopy={() => {
                const shareLinkTmp = shareLink.value;
                shareLink.setValue('복사 완료!');
                setTimeout(() => {
                  shareLink.setValue(shareLinkTmp);
                }, 700);
              }}
            >
              <IconButton>
                <Copy />
              </IconButton>
            </CopyToClipboard>
          </LinkContainer>
        </ShareContainer>
      </Form>
    </div>
  </ManageWrapper>
);

export default forwardRef(ManagePresenter);

const ManageWrapper = styled(Wrapper)`
  a {
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
  }
  span,
  div {
    word-wrap: break-word;
  }
  .control-wrap {
    border-bottom: 1px solid #999;
    > p {
      font-wight: 500;
      color: #999;
    }
  }
  .control-switch {
    display: flex;
    align-items: center;
    padding: 15px 0px;
  }
  .test {
    color: red;
    padding-bottom: 20px;
  }
  .top-item {
    margin-top: -20px;
  }
`;

const NavButtonRed = styled(NavButton)`
  color: white;
  background: red;
  border-color: red;
  :hover {
    color: red;
    background: white;
  }
`;

const ToggleControlRows = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #999;
`;

const ControlItemTitle = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 800;
`;

const ShareContainer = styled.div`
  padding-top: 20px;
`;

const QRContainer = styled.div`
  text-align: center;
  margin: -30px;
  margin-top: 0px;
  button {
    margin: 0;
    position: relative;
    top: -${props => props.width / 10}px;
    left: ${props => props.width / 2 - 54}px;
  }
`;
const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px
  margin-bottom: 25px;
  input {
    &:not(:last-child) {
      margin: 0;
    }
  }
  button {
    padding: 0;
    margin-left: 15px;
    margin-bottom: 3px;
  }
  svg {
    margin: 0;
  }
`;

const NavBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: 20px;
  button {
    :first-child {
      margin-right: 4px;
    }
    :last-child {
      margin-left: 4px;
    }
  }
`;

const IpItem = styled.span`
  padding: 5px;
  margin: 5px;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
`;

const TitleText = styled.div`
  font-size: 26px;
  font-weight: 800;
`;

const DescriptionText = styled.div`
  fontweight: 500;
  color: '#999';
  padding-top: 10px;
  padding-bottom: 20px;
`;

const WifiInfo = styled.div`
  padding: 0px 8px;
  font-size: 16px;
  font-weight: 600;
  li {
    display: inline;
    margin: 0px 8px;
  }
`;
