import { observable, action } from 'mobx';
import QuestionStore from './question';

export default class PollStore {
  @observable id;
  @observable title;
  @observable description;
  @observable titleImage;
  @observable isOpen;
  @observable usingLocation;
  @observable usingMessage;
  @observable usingLot;
  @observable imageChanged;
  @observable wifi;
  @observable ips;
  @observable questions;
  @observable creditModel;

  questionIndex;
  ipIndex;

  constructor() {
    this.reset();
  }

  @action
  reset = () => {
    this.id = '';
    this.title = '';
    this.description = '';
    this.titleImage = '';
    this.ips = [];
    this.wifi = { ssid: '', password: '' };
    this.creditModel = null;

    const newQuestion = new QuestionStore(this, 0);
    this.questions = [newQuestion];
    this.questionIndex = 1;

    this.ipIndex = 1;

    // Local state로 빼서 상태 명확히 할 필요 있을수도
    this.isOpen = false;
    this.usingLocation = false;
    this.usingMessage = false;
    this.usingLot = false;
    this.imageChanged = false;
  };

  @action
  build = ({
    id,
    title,
    description,
    titleImage,
    wifi,
    ips,
    isOpen,
    usingLocation,
    usingMessage,
    usingLot,
    questions,
    creditModel
  }) => {
    this.id = id;
    this.title = title;
    this.description = description;
    this.titleImage = titleImage;
    this.wifi = wifi;
    this.ips = ips.map((ip, index) => ({
      index: index + 1,
      address: ip.address
    }));
    this.ipIndex = this.ips.length + 1;
    this.isOpen = isOpen;
    this.usingLocation = usingLocation;
    this.usingMessage = usingMessage;
    this.usingLot = usingLot;
    this.questions = questions.map((question, index) => {
      const newQuestion = new QuestionStore(this);
      newQuestion.build(question, index);
      return newQuestion;
    });
    this.creditModel = creditModel;
    this.imageChanged = false;
  };

  @action
  handleCreditModelSelect = value => {
    this.creditModel = value;
  };

  @action
  onChange = e => {
    const { name, value } = e.target;
    if (name === 'description' && value.length > 250) return;
    if (name === 'title' && value.length > 150) return;
    this[name] = value;
  };

  @action
  onImageDrop = images => {
    this.titleImage = images[images.length - 1];
    this.imageChanged = true;
  };

  @action
  toggleUsingLocation = () => {
    if (this.usingLocation) {
      this.usingLocation = false;
    } else {
      this.usingLocation = true;
    }
  };

  @action
  toggleUsingMessage = () => {
    if (this.usingMessage) {
      this.usingMessage = false;
    } else {
      this.usingMessage = true;
    }
  };

  @action
  toggleUsingLot = () => {
    if (this.usingLot) {
      this.usingLot = false;
    } else {
      this.usingLot = true;
    }
  };

  /**
   * Question
   */
  @action
  addQuestion = () => {
    if (this.questions.length < 15) {
      const newQuestion = new QuestionStore(this, this.questionIndex++);
      this.questions.push(newQuestion);
    }
  };

  @action
  removeQuestion = e => {
    const { value: index } = e.target;
    this.questions = this.questions.filter(
      question => question.index !== parseInt(index)
    );
  };

  @action
  handleWifiChange = e => {
    const { name, value } = e.target;
    this.wifi[name] = value;
  };

  /**
   * Ip
   */
  @action
  addIp = address => {
    if (this.ips.length < 15) {
      if (address) {
        const exist = this.ips.map(ip => ip.address);
        if (!exist.includes(address)) {
          this.ips.push({ index: this.ipIndex++, address });
        }
      } else {
        this.ips.push({ index: this.ipIndex++, address: '' });
      }
    }
    return this.ipIndex;
  };

  @action
  removeIp = e => {
    const { name: index } = e.target;
    this.ips = this.ips.filter(ip => ip.index !== parseInt(index));
  };

  @action
  onIpChange = e => {
    const { name: index, value } = e.target;
    if (value.length > 15) return;
    this.ips[index].address = value;
  };
}
