import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Form from '../../Components/Form';
import Box from '../../Components/Box';
import Wrapper from '../../Components/Wrapper';
import PollinLogo from '../../Components/PollinLogo';

export default ({
  action,
  username,
  email,
  setAction,
  onSubmit,
  secret,
  loadingButton
}) => (
  <Wrapper>
    <PollinLogo />
    <Form>
      <Helmet title='Pollin' />
      {action === 'logIn' && (
        <>
          <InfoMessage>투표 개설은 로그인 이후 가능합니다</InfoMessage>
          <form onSubmit={onSubmit}>
            <Input placeholder={'Email'} {...email} type='email' />
            <Button loading={loadingButton} text={'로그인'} />
          </form>
        </>
      )}
      <form onSubmit={onSubmit}>
        {action === 'signUp' && (
          <>
            <InfoMessage>메일 주소로 간단하게 가입하세요!</InfoMessage>
            <Input placeholder={'Email'} {...email} type='email' />
            <Input placeholder={'Nickname'} {...username} />
            <Button loading={loadingButton} text={'가입하기'} />
          </>
        )}
        {action === 'confirm' && (
          <>
            <InfoMessage>이메일로 전송된 보안코드를 입력해 주세요</InfoMessage>
            <Input
              placeholder={'Email'}
              {...email}
              type='email'
              disabled={true}
            />
            <Input placeholder='보안코드' required {...secret} />
            <Button loading={loadingButton} text={'확인'} />
          </>
        )}
      </form>
    </Form>
    {action !== 'confirm' && (
      <StateChanger>
        {action === 'logIn' ? (
          <>
            아직 회원이 아닌가요?{' '}
            <Link onClick={() => setAction('signUp')}>가입하기</Link>
          </>
        ) : (
          <>
            이미 회원인가요?{' '}
            <Link onClick={() => setAction('logIn')}>로그인</Link>
          </>
        )}
      </StateChanger>
    )}
  </Wrapper>
);

const InfoMessage = styled.div`
  align-self: flex-start;
  font-size: 16px;
  font-weight: 600;
  color: #999;
  padding-bottom: 20px;
`;

const StateChanger = styled(Box)`
  text-align: center;
  padding: 20px 0px;
`;

const Link = styled.span`
  color: #3897f0;
  cursor: pointer;
`;
