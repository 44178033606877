import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import Theme from '../Styles/Theme';
import Routes from './Routes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from './Footer';
import { ApolloProvider } from 'react-apollo-hooks';
import Client from '../Apollo/Client';
import PollStore from '../stores/poll';
import UserStore from '../stores/user';
import { Provider } from 'mobx-react';
import GlobalStyles from '../Styles/GlobalStyles';

const poll = new PollStore();
const user = new UserStore();

export default () => {
  return (
    <ApolloProvider client={Client}>
      <ThemeProvider theme={Theme}>
        <CookiesProvider>
          <HelmetProvider>
            <Router>
              <Helmet title='Pollin' />
              <GlobalStyles />
              <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
              <Wrapper>
                <Provider poll={poll} user={user}>
                  <Routes />
                </Provider>
              </Wrapper>
              <Footer />
            </Router>
          </HelmetProvider>
        </CookiesProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 935px;
  width: 100%;
`;
