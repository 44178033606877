import React from 'react';
import styled from 'styled-components';

const Emoji = ({ symbol, label, size = '14px', className, alignSelf }) => (
  <Container
    className={className}
    role='img'
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
    size={size}
    alignSelf={alignSelf}
  >
    {symbol}
  </Container>
);

export default Emoji;

const Container = styled.span`
  font-size: ${props => props.size};
  align-self: ${props => props.alignSelf};
`;
