import React, { useState } from 'react';
import useInput from '../../Hooks/useInput';
import AuthPresenter from './AuthPrensenter';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-apollo-hooks';
import {
  LOG_IN,
  CREATE_USER,
  CONFIRM_SECRET,
  LOCAL_LOG_IN
} from '../../queries';
import { toast } from 'react-toastify';

export default withRouter(() => {
  const username = useInput('');
  const email = useInput('');
  const secret = useInput('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [action, setAction] = useState('logIn');
  const [requestSecretMutation] = useMutation(LOG_IN, {
    variables: { email: email.value }
  });
  const [createUserMutation] = useMutation(CREATE_USER, {
    variables: {
      email: email.value,
      username: username.value
    }
  });
  const [confirmSecretMuatation] = useMutation(CONFIRM_SECRET, {
    variables: {
      email: email.value,
      secret: secret.value
    }
  });
  const [localLogInMutation] = useMutation(LOCAL_LOG_IN);

  const onSubmit = async e => {
    e.preventDefault();
    setLoadingButton(true);
    if (action === 'logIn') {
      if (email.value !== '') {
        try {
          const {
            data: { requestSecret }
          } = await requestSecretMutation();
          if (!requestSecret) {
            toast.error('등록된 메일 주소가 없습니다. 지금 가입해 보세요.');
            setTimeout(() => setAction('signUp'), 1500);
          } else {
            toast.success('메일함에서 보안코드를 확인하세요.');
            setAction('confirm');
          }
        } catch {
          toast.error(
            '보안코드를 요청할 수 없습니다. 잠시 후 다시 시도해 보세요.'
          );
        } finally {
          setLoadingButton(false);
        }
      } else {
        toast.error('필수항목을 입력해주세요.');
        setLoadingButton(false);
      }
    } else if (action === 'signUp') {
      if (username !== '' && email !== '') {
        try {
          const {
            data: { createUser }
          } = await createUserMutation();
          if (!createUser) {
            toast.error('지금은 메일을 등록할 수 없습니다.');
          } else {
            toast.success(
              '메일 등록이 완료됐습니다. 로그인 페이지로 이동합니다.'
            );
            setTimeout(() => setAction('logIn'), 1500);
          }
        } catch (err) {
          if (err.graphQLErrors[0]) {
            const errorMessage = err.graphQLErrors[0].message;
            if (errorMessage === '이미 사용중인 이메일 주소입니다.') {
              toast.error(
                '이미 사용중인 메일주소입니다. 로그인 후 사용해 주세요.'
              );
              setTimeout(() => setAction('logIn'), 1500);
            } else if (errorMessage === '이미 사용중인 유저 닉네임입니다.') {
              toast.error(errorMessage);
            }
          }
        } finally {
          setLoadingButton(false);
        }
      } else {
        toast.error('필수항목을 모두 입력해 주세요.');
        setLoadingButton(false);
      }
    } else if (action === 'confirm') {
      if (secret.value !== '') {
        try {
          const {
            data: { confirmSecret: token }
          } = await confirmSecretMuatation();
          if (token !== '' && token !== undefined) {
            await localLogInMutation({ variables: { token } });
          } else {
            throw Error();
          }
        } catch {
          toast.error('유효하지 않은 보안코드입니다. 다시 확인해 주세요.');
          setLoadingButton(false);
        }
      } else setLoadingButton(false);
    }
  };

  return (
    <AuthPresenter
      setAction={setAction}
      action={action}
      username={username}
      email={email}
      secret={secret}
      onSubmit={onSubmit}
      loadingButton={loadingButton}
    />
  );
});
