import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  display: flex;
  flex: 1;
  font-size: 26px;
  font-weight: 800;
  align-items: center;
`;

const LargeText = ({ className, text }) => (
  <Text className={className}>{text}</Text>
);
export default LargeText;
